import {
	AreaStatus,
	InstallationProgress,
	JobStatus,
	PremiseStatus,
	ProgressNote,
	StepStatus
} from './InstallationProgress';
import { InstallationPremise } from '../../components/installation/model/InstallationPremise';
import { InstallationJob } from '../../components/installation/model/InstallationJob';
import { InstallationArea } from '../../components/installation/model/InstallationArea';
import { Installation } from '../../components/installation/model/Installation';
import { CodeWithRaw } from '../../components/installation/validation/progress-view/DeviceList';

type ProgressParams = {
	premiseId?: string;
	jobId?: string;
	step?: number;
};

export class InstallationProgressWrapper {
	readonly progress: InstallationProgress;
	readonly installation: Installation;

	constructor(progress: InstallationProgress, installation: Installation) {
		this.progress = progress;
		this.installation = installation;
	}

	getNote(noteIndex: number, params: ProgressParams): ProgressNote | undefined {
		const noteParent = this.findNoteParent(params);
		return noteParent?.notes[noteIndex];
	}

	private findNoteParent({
		premiseId,
		jobId,
		step
	}: {
		premiseId?: string;
		jobId?: string;
		step?: number;
	}): {
		notes: ProgressNote[];
	} {
		if (step !== undefined) return this.progress.premises[premiseId ?? '']?.jobs[jobId ?? '']?.steps[step];
		else if (jobId !== undefined) return this.progress.premises[premiseId ?? '']?.jobs[jobId ?? ''];
		else if (premiseId !== undefined) return this.progress.premises[premiseId ?? ''];
		else return this.progress;
	}

	getPremiseProgress(premise: InstallationPremise) {
		const { premiseMarking } = premise;
		return this.getPremiseProgressByMarking(premiseMarking);
	}

	getPremiseProgressByMarking(premiseMarking: string) {
		if (!this.progress.premises[premiseMarking]) {
			this.progress.premises[premiseMarking] = {
				jobs: {},
				notes: []
			};
		}
		return this.progress.premises[premiseMarking];
	}

	getJobProgress(premise: InstallationPremise, job: InstallationJob) {
		const premiseProgress = this.getPremiseProgress(premise);
		const { id } = job;
		if (!premiseProgress.jobs[id]) {
			premiseProgress.jobs[id] = {
				steps: {},
				notes: []
			};
		}
		return premiseProgress.jobs[id];
	}

	getJobIdsByCode(premiseMarking: string, code: string) {
		const premiseProgress = this.getPremiseProgressByMarking(premiseMarking);

		return Object.entries(premiseProgress.jobs)
			.filter(e => Object.values(e[1].steps).some(s => s.code === code))
			.map(e => e[0]);
	}

	getStepProgress(premise: InstallationPremise, job: InstallationJob, step: number) {
		const jobProgress = this.getJobProgress(premise, job);
		if (!jobProgress.steps[step]) {
			jobProgress.steps[step] = {
				notes: []
			};
		}
		return jobProgress.steps[step];
	}

	getStepStatus(premise: InstallationPremise, job: InstallationJob, index: number): StepStatus {
		const stepProgress = this.getStepProgress(premise, job, index);
		return stepProgress.status ?? 'todo';
	}

	getAreaStatusAndCounts(area?: InstallationArea) {
		if (!area) {
			return {
				status: 'empty' as AreaStatus,
				counts: { todo: 0, done: 0, partially_done: 0, empty: 0 }
			};
		}
		const premiseStatuses = area.premises.map(premise => this.getPremiseStatusAndCounts(premise).status);

		const counts = getStatusCounts(premiseStatuses, { todo: 0, done: 0, partially_done: 0, empty: 0 });
		const status = toAreaStatus(counts);

		return {
			counts,
			status
		};
	}

	getPremiseStatusAndCounts(premise?: InstallationPremise) {
		if (!premise) {
			return {
				status: 'empty' as PremiseStatus,
				counts: { todo: 0, done: 0, partially_done: 0, empty: 0 }
			};
		}

		const jobs = premise.jobIds.map(jobId => this.installation.jobs.find(j => j.id === jobId));
		const jobStatuses = jobs.map(job => this.getJobStatusAndCounts(premise, job).status);

		const counts = getStatusCounts(jobStatuses, { todo: 0, done: 0, partially_done: 0, empty: 0 });
		const status = toPremiseStatus(counts);

		return {
			counts,
			status
		};
	}

	getJobStatusAndCounts(premise: InstallationPremise, job?: InstallationJob) {
		if (!job) {
			return {
				status: 'empty' as JobStatus,
				counts: { todo: 0, done: 0, partially_done: 0, empty: 0 }
			};
		}
		const counts = getStatusCounts(
			job.steps.map((_, index) => this.getStepStatus(premise, job, index)),
			{ todo: 0, done: 0 }
		);
		const status = toJobStatus(counts);

		return {
			counts,
			status
		};
	}

	getSensorCodes(installation: Installation, selectedPremise: InstallationPremise): CodeWithRaw[] {
		const progress = this.getPremiseProgress(selectedPremise);
		return Object.entries(progress.jobs)
			.map(([id, jobProgress]) => ({
				job: installation.jobs.find(j => j.id === id),
				jobProgress: jobProgress
			}))
			.filter(({ job }) => job && (job.type.startsWith('SENSOR_') || job.type.startsWith('PRESSDIFF_')))
			.map(({ job, jobProgress }) => ({
				job: job!,
				steps: Object.values(jobProgress.steps)
			}))
			.flatMap(item =>
				item.steps.map(step => ({
					code: step.code!,
					rawBarCode: step.rawBarCode,
					status:
						item.job && (item.job.type === 'SENSOR_REMOVAL' || item.job.type === 'PRESSDIFF_REMOVAL')
							? 'REMOVED'
							: 'INSTALLED'
				}))
			)
			.filter(s => !!s.code);
	}

	getFeelisCodes(installation: Installation, selectedPremise: InstallationPremise) {
		const progress = this.getPremiseProgress(selectedPremise);
		return Object.entries(progress.jobs)
			.filter(([id]) => {
				const job = installation.jobs.find(j => j.id === id);
				return job && job.type.startsWith('FEELIS_');
			})
			.map(([id, jobProgress]) => ({
				job: installation.jobs.find(j => j.id === id)!,
				steps: Object.values(jobProgress.steps)
			}))
			.flatMap(item =>
				item.steps.map(step => ({
					code: step.code!,
					rawBarCode: step.rawBarCode,
					status: item.job && item.job.type === 'FEELIS_SIGN_REMOVAL' ? 'REMOVED' : 'INSTALLED'
				}))
			)
			.filter(s => !!s.code);
	}

	getPremiseCodesByPremiseMarking(premiseMarking: string): CodeWithRaw[] {
		return Object.values(this.getPremiseProgressByMarking(premiseMarking).jobs)
			.flatMap(j => Object.values(j.steps))
			.filter(s => !!s.code)
			.map(s => s as CodeWithRaw);
	}

	getAllNotesFromPremise(premise: InstallationPremise) {
		const { jobs, notes } = this.getPremiseProgress(premise);
		return Object.values(jobs).flatMap(job => [
			...Object.values(job.steps).flatMap(step => step.notes),
			...job.notes,
			...notes
		]);
	}

	getAllPicturesFromPremise(premise: InstallationPremise) {
		const { jobs } = this.getPremiseProgress(premise);
		return Object.values(jobs).flatMap(job =>
			Object.values(job.steps)
				.flatMap(step => step.picture)
				.filter((p): p is string => p !== undefined)
		);
	}
}

function toAreaStatus(counts: Partial<Record<PremiseStatus, number>>): AreaStatus {
	const complete = counts.done ?? 0;
	const incomplete = (counts.partially_done ?? 0) + (counts.todo ?? 0);
	if (incomplete > 0) {
		if (complete > 0) return 'partially_done';
		else return 'todo';
	} else if (complete > 0) return 'done';
	else return 'empty';
}

function toPremiseStatus(counts: Partial<Record<JobStatus, number>>): PremiseStatus {
	const complete = counts.done ?? 0;
	const incomplete = (counts.partially_done ?? 0) + (counts.todo ?? 0);
	if (incomplete > 0) {
		if (complete > 0) return 'partially_done';
		else return 'todo';
	} else if (complete > 0) return 'done';
	else return 'empty';
}

function toJobStatus(counts: Partial<Record<StepStatus, number>>): JobStatus {
	if ((counts.todo ?? 0) > 0) {
		if ((counts.done ?? 0) > 0) return 'partially_done';
		else return 'todo';
	} else if ((counts.done ?? 0) > 0) return 'done';
	else return 'empty';
}

function getStatusCounts<TStatus extends string>(
	statuses: TStatus[],
	init: Record<TStatus, number>
): Record<TStatus, number> {
	const ret = init;
	statuses.forEach(s => {
		ret[s] = (ret[s] ?? 0) + 1;
	});
	return ret;
}
