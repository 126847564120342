import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { useState } from 'react';
import { Insight, InsightHoldReason, Status } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { InsightStateButton } from '../../../buttons/InsightStateButton';
import { HoldInsightFormSchema } from './getHoldInsightFormSchema';
import { HoldInsightModal } from './HoldInsightModal';

interface Props {
	insight: Insight;
	currentUser: UserModel;
	dataCy?: string;
	fixedReason?: InsightHoldReason;
}

export function HoldInsight({ insight, currentUser, dataCy, fixedReason }: Props) {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<InsightStateButton
				icon={<HourglassEmptyOutlinedIcon style={{ fontSize: '1.9rem' }} />}
				label="action.hold"
				onClick={() => setModalOpen(true)}
				color="#F7CF4F"
				dataCy={dataCy}
			/>
			<HoldInsightModal
				insight={insight}
				modalOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				fixedReason={fixedReason}
				currentUser={currentUser}
			/>
		</>
	);
}
