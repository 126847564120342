import { createUuid } from '../../../../hooks/useUuid';
import { FreesiButton } from '../../../styled/FreesiButton';
import { useInstallationContext } from '../../InstallationContext';
import { InstallationJob } from '../../model/InstallationJob';
import CreateIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
	template: InstallationJob;
	closeModal: () => void;
	isMobile: boolean;
}

export function CreateJobFromTemplate({ template, closeModal, isMobile }: Props) {
	const installation = useInstallationContext();

	async function handleCloneJobFromTemplate(t: InstallationJob) {
		if (t) {
			t.id = createUuid();
			await installation.createJob(t);
			closeModal();
		}
	}

	return (
		<FreesiButton
			label="installation.createInstallationJob"
			onClick={() => handleCloneJobFromTemplate(template)}
			disabled={installation.getStatus() !== 'PLANNING'}
			icon={isMobile ? <CreateIcon /> : undefined}
		/>
	);
}
