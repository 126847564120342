import { useFormContext } from 'react-hook-form';
import { useSensorManufacturer } from '../../../../hooks/useSensorManufacturers';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { InputLabelWithRequired } from '../../../form/InputLabelWithRequired';
import { ControlledTextField } from '../../../form/controls/ControlledTextField';
import { MultiSelectInput } from '../../../form/controls/MultiSelectInput';
import { Column, Row } from '../../../modal/modalUtils';
import { InstallationJob } from '../../model/InstallationJob';
import { sortBy, uniq } from 'lodash';

export function JobMetadataField() {
	const form = useFormContext<InstallationJob>();
	const type = form.watch('type');
	const { unsafeTranslation } = useTypedTranslation();
	const { manufacturers } = useSensorManufacturer();

	if (!type) return null;

	const models = manufacturers?.flatMap(m => m.models);
	const fields = getMetadataFields(type);

	return (
		<>
			<Row gap="4%" alignItems="flex-end" style={{ flexWrap: 'wrap' }}>
				{fields.map(field => (
					<Column key={field} style={{ maxWidth: '48%' }}>
						<InputLabelWithRequired
							label=""
							labelRaw={unsafeTranslation(`installation.jobs.metadata.${field.toUpperCase()}`)}
							required
						/>
						<ControlledTextField name={`metadata.${field}`} fullWidth key={field} />
					</Column>
				))}
				<Column style={{ maxWidth: '48%' }}>
					<InputLabelWithRequired label="installation.jobs.metadata.ICON_CHAR" />
					<ControlledTextField name="iconChar" fullWidth />
				</Column>
				{!type.startsWith('FEELIS') && (
					<Column style={{ maxWidth: '48%', marginTop: '20px' }}>
						<InputLabelWithRequired label="sensors-view.details-modal.model" />
						<MultiSelectInput name="metadata.models" options={models ? sortBy(uniq(models)) : []} fullWidth />
					</Column>
				)}
			</Row>
		</>
	);
}

function getMetadataFields(type: InstallationJob['type']): string[] {
	switch (type) {
		case 'SENSOR_INSTALL':
		case 'PRESSDIFF_INSTALL':
			return ['sensorType', 'marking'];
		case 'SENSOR_BATTERY_CHANGE':
		case 'SENSOR_REMOVAL':
		case 'PRESSDIFF_REMOVAL':
			return ['sensorType'];
		default:
			return ['marking'];
	}
}
