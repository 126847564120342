import { useTypedTranslation } from '../../../../../hooks/useTypedTranslation';
import { createRejectedApproval, Insight, LogEntryType, Status } from '../../../../../models/Insights';
import { UserModel } from '../../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../../stores/insights/InsightStore';
import { FormId } from '../../../../form/FormId';
import { FreesiModal } from '../../../FreesiModal';
import { ResolveInsightFormSchema } from '../getResolveInsightFormSchema';
import { ResolveInsightForm } from '../ResolveInsightForm';

interface Props {
	insight: Insight;
	currentUser: UserModel;
	dataCy?: string;
	modalOpen: boolean;
	onClose: () => void;
}

export function RejectApprovalRequest({ insight, currentUser, modalOpen, onClose }: Props) {
	const { t } = useTypedTranslation();
	const { resumePendingInsight, recordApprovalForInsightCompletion } = useInsightActions();
	const { buildInsightRequest } = useBuildInsightRequest();

	async function handleRejectApprovalRequest(data: ResolveInsightFormSchema) {
		const logEntry = {
			responsible: data.responsibleEmail,
			expectedDate: data.expectedDate,
			message: data.message,
			attachments: data.attachments,
			type: LogEntryType.APPROVAL_REJECTED
		};
		const approval = createRejectedApproval(currentUser.email, new Date(), data.message);
		const handleRequest = buildInsightRequest(Status.IN_PROGRESS, logEntry, data.tasks);
		await resumePendingInsight(insight.id, handleRequest);
		await recordApprovalForInsightCompletion(insight.id, approval);
	}

	return (
		<FreesiModal
			maxWidth="sm"
			open={modalOpen}
			onClose={onClose}
			title={t('action.reject')}
			cancelable
			onCancel={onClose}
			submittable
			formId={FormId.INSIGHT}>
			<ResolveInsightForm
				onSubmit={handleRejectApprovalRequest}
				includeAddTasks
				extraInfoText={t('eventModal.approval.reject-action')}
			/>
		</FreesiModal>
	);
}
