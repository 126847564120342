import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import { useState } from 'react';
import { Insight, InsightHoldReason, Status } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { Column } from '../../modalUtils';
import { DeclineInsightModal } from './DeclineInsightModal';
import { HoldInsightModal } from './HoldInsightModal';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';

type Modal = 'hold' | 'decline';

interface Props {
	insight: Insight;
	currentUser: UserModel;
}

export function MoreActions({ insight, currentUser }: Props) {
	const { t } = useTypedTranslation();
	const [modal, setModal] = useState<Modal>();
	const { pauseInsight } = useInsightActions();
	const { buildInsightLogEntry } = useBuildInsightRequest();

	function handleCloseModal() {
		setModal(undefined);
	}

	async function handleKeepMonitorInsight() {
		const logEntry = {
			message: '',
			reason: InsightHoldReason.FOLLOWUP
		};

		const handleRequest = buildInsightLogEntry(logEntry, Status.PENDING);
		await pauseInsight(insight.id, handleRequest);
	}

	const actions = [
		{ icon: <EuroOutlinedIcon />, name: t('eventModal.CAPEX.capex-request'), action: () => setModal('hold') },
		{ icon: <BlockOutlinedIcon />, name: t('eventModal.decline-insight'), action: () => setModal('decline') }
	];

	if (insight.latest?.reason !== InsightHoldReason.FOLLOWUP)
		actions.push({
			icon: <HourglassEmptyOutlinedIcon />,
			name: t('eventModal.keep-monitoring'),
			action: () => handleKeepMonitorInsight()
		});

	return (
		<>
			<Column style={{ justifyContent: 'flex-start', gap: '10px' }}>
				<SpeedDial
					sx={{
						'.MuiSpeedDial-root': { width: '70px', height: '70px' },
						'.MuiSpeedDial-fab': {
							width: '70px',
							height: '70px',
							border: '2px solid #00195f',
							backgroundColor: 'transparent',
							boxShadow: 'none',
							color: '#00195f'
						}
					}}
					ariaLabel="More Actions"
					icon={<PendingOutlinedIcon style={{ fontSize: '1.9rem' }} />}
					direction="right">
					{actions.map(action => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							tooltipPlacement="top"
							onClick={action.action}
						/>
					))}
				</SpeedDial>
			</Column>
			<HoldInsightModal
				modalOpen={modal === 'hold'}
				onClose={handleCloseModal}
				insight={insight}
				currentUser={currentUser}
				fixedReason={InsightHoldReason.CAPEX}
			/>
			<DeclineInsightModal insight={insight} modalOpen={modal === 'decline'} handleModalClose={handleCloseModal} />
		</>
	);
}
