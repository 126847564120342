import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { LocationHeader } from '../../../models/Building';
import { Option } from '../../../models/Option';
import {
	NewInstallation,
	useAllInstallations,
	useInstallationActions
} from '../../../stores/installation/InstallationStore';
import { useLocationHeaders } from '../../../stores/location/LocationHeaderStore';
import { useTypePhrase } from '../../../utils/i18nUtils';
import { StyledSearchBar } from '../../control-room/ControlRoom.styles';
import { FilterBarField } from '../../control-room/views/list-view/filter-bar/FilterBarField';
import DelaySpinner from '../../delay-spinner/DelaySpinner';
import { FormId } from '../../form/FormId';
import { FreesiModal } from '../../modal/FreesiModal';
import { Search } from '../../search/Search';
import { FreesiButton } from '../../styled/FreesiButton';
import { Installation } from '../model/Installation';
import { InstallationForm } from './InstallationForm';
import { InstallationWithLocationName, InstallationsTable } from './InstallationsTable';
import { DrawerMenu } from '../../drawer-menu/DrawerMenu';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { InstallationJobTemplates } from '../jobs/templates-list/InstallationJobTemplates';
import { Column } from '../../modal/modalUtils';

const installationStatuses = ['ONGOING', 'PLANNING', 'INSTALLING', 'VERIFYING', 'COMPLETED', 'CANCELLED'];

export function InstallationListView() {
	const isMobile = useIsMobile();
	const { t, unsafeTranslation } = useTypedTranslation();
	const { data: installations } = useAllInstallations();
	const { createInstallation } = useInstallationActions();
	const { data: locations } = useLocationHeaders();

	const [modalOpen, setModalOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [location, setLocation] = useState<Option>();
	const statusOptions = installationStatuses.map(s => ({
		value: s,
		label: unsafeTranslation(`installation.status.${s}`)
	}));
	const [status, setStatus] = useState<Option | undefined>(statusOptions[0]);
	const locationPlaceholder = useTypePhrase('general.location', 'general.phrases.select')();
	const statusPlaceholder = useTypePhrase('general.status', 'general.phrases.select')();

	async function onSubmit(data: NewInstallation) {
		await createInstallation(data);
		setModalOpen(false);
	}

	if (!installations || !locations) return <DelaySpinner />;

	const withLocationNames = installations.map(i => ({ ...i, locationName: findLocationName(locations, i) }));
	const locationOptions = withLocationNames
		.map(i => ({ value: i.locationId, label: i.locationName ?? i.locationId }))
		.filter((option, index, self) => index === self.findIndex(t => t.value === option.value));

	const installationsFiltered = withLocationNames.filter(
		i => locationMatches(i, location?.label || searchQuery) && statusMatches(i, status)
	);

	return (
		<div className="flex-container is-scrollable">
			<div className={isMobile ? undefined : 'container is-fluid margin-both-20'}>
				<StyledSearchBar style={{ justifyContent: 'center', gap: 20, alignItems: 'center' }}>
					<FilterBarField
						value={status}
						onSelect={v => setStatus(v)}
						options={statusOptions}
						label={statusPlaceholder}
					/>
					<FilterBarField
						value={location}
						onSelect={v => setLocation(v)}
						options={locationOptions}
						label={locationPlaceholder}
					/>
					<Search
						onSearch={s => setSearchQuery(s.join(', '))}
						placeholder="search.search-installation"
						width="40%"
						searchTriggerMode="instant"
					/>
					<FreesiButton
						label="installation.createInstallation"
						onClick={() => setModalOpen(true)}
						icon={isMobile ? <AddCircleIcon /> : undefined}
					/>
					<DrawerMenu
						buttonLabel="installation.jobTemplate_plural"
						buttonStyle={{ backgroundColor: '#00195f' }}
						icon={<BackupTableIcon style={{ color: '#5bf7bf' }} />}
						content={<InstallationJobTemplates isStandAlone />}
					/>
				</StyledSearchBar>
				<InstallationsTable installations={installationsFiltered} />
				<FreesiModal
					open={modalOpen}
					maxWidth="sm"
					onCancel={() => setModalOpen(false)}
					cancelable
					submittable
					title={t('installation.createInstallation')}
					formId={FormId.INSTALLATION_CREATE}>
					<InstallationForm onSubmit={onSubmit} />
				</FreesiModal>
			</div>
		</div>
	);
}

function findLocationName(locations: LocationHeader[], installation: Installation) {
	return locations.find(l => l.id === installation.locationId)?.name;
}

function locationMatches(installation: InstallationWithLocationName, searchQuery: string) {
	return queryMatches(
		[installation.locationName, installation.id, installation.title, installation.status, installation.description],
		searchQuery
	);
}

function queryMatches(data: (string | undefined)[], query: string) {
	return data.some(v => v && v.toLowerCase().includes(query.trim().toLowerCase()));
}

function statusMatches(installation: InstallationWithLocationName, selectedStatus?: Option) {
	if (!selectedStatus) {
		return true;
	}
	if (selectedStatus.value === 'ONGOING') {
		return installation.status !== 'COMPLETED' && installation.status !== 'CANCELLED';
	}
	return installation.status === selectedStatus.value;
}
