import { useEffect, useState } from 'react';

export function useForceRemount(deps: unknown[]) {
	const [canMount, setCanMount] = useState(true);
	useEffect(() => {
		setCanMount(false);
		setTimeout(() => setCanMount(true), 0);
	}, deps);
	return canMount;
}
