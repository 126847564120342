import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import { useState } from 'react';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight, LogEntryType, Status } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { formatTimestampUTC } from '../../../../utils/dateTimeFormat';
import { InsightStateButton } from '../../../buttons/InsightStateButton';
import { Column } from '../../modalUtils';
import { LabelValueRow } from '../LabelValueRow';
import { ProcessInsightModal } from './ProcessInsightModal';

interface Props {
	insight: Insight;
	currentUser: UserModel;
	dataCy?: string;
}

export function ResolveCAPEX({ insight, currentUser, dataCy }: Props) {
	const { t } = useTypedTranslation();
	const { resumePendingInsight, sendCAPEX } = useInsightActions();
	const { buildInsightRequest } = useBuildInsightRequest();

	const [modalOpen, setModalOpen] = useState(false);
	const capexRequest = insight.capexRequest;
	const canUserResolveCAPEX = currentUser.email === insight.latest?.responsibleEmail;

	async function handleResolveCAPEX(message: string, attachments: string[]) {
		const capexDecision = {
			decision: message,
			decisionMaker: currentUser.fullName,
			decisionDate: new Date().toISOString()
		};
		const logEntry = {
			responsible: insight.supervisor?.email,
			message,
			attachments,
			type: LogEntryType.CAPEX_DECISION
		};
		const handleRequest = buildInsightRequest(Status.IN_PROGRESS, logEntry);
		await resumePendingInsight(insight.id, handleRequest);
		await sendCAPEX(insight.id, capexDecision);
	}

	const capexRequestFields = [
		{ label: t('eventModal.CAPEX.requester'), value: capexRequest?.requestedBy },
		{
			label: t('eventModal.CAPEX.requestDate'),
			value: capexRequest?.requestedDate ? formatTimestampUTC(capexRequest.requestedDate) : undefined
		},
		{ label: t('general.amount'), value: capexRequest?.amount ? capexRequest?.amount + ' €' : undefined },
		{ label: t('general.reason'), value: capexRequest?.reason }
	];

	const capexRequestComponent = (
		<Column>
			{capexRequestFields.map((f, index) => (
				<LabelValueRow key={index} label={f.label} value={f.value} />
			))}
		</Column>
	);

	return (
		<>
			<InsightStateButton
				icon={<EuroOutlinedIcon style={{ fontSize: '1.9rem' }} />}
				label="action.resolve"
				onClick={() => setModalOpen(true)}
				dataCy={dataCy}
				disabled={!canUserResolveCAPEX}
			/>
			<ProcessInsightModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				modalLabel="eventModal.resolve-capex"
				messageLabel="eventModal.CAPEX.decision"
				onSubmit={handleResolveCAPEX}
				extraChildren={capexRequest ? capexRequestComponent : undefined}
			/>
		</>
	);
}
