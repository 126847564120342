import { TranslateFn } from '../../../../hooks/useTypedTranslation';
import { TranslationKeys } from '../../../../locales/TranslationKeys';
import { Insight, InsightLogEntry, LogEntryType, Status, Task } from '../../../../models/Insights';

export interface MappedTimelineEntry {
	title: string;
	completed: boolean;
	completedTime: string;
	createdBy?: string;
	logs: Array<{
		createdBy?: string;
		message: string;
		created: string;
		type?: LogEntryType;
		attachments?: string[];
		editable: boolean;
	}>;
	id?: string;
	status?: Status;
	reason?: string;
}

export const mapTasksAndLogs = (insight: Insight, translate: TranslateFn): MappedTimelineEntry[] => {
	const mappedTimelineEntries: MappedTimelineEntry[] = [];
	const taskEntryMap: { [key: string]: MappedTimelineEntry } = {};

	mapTasksToTimelineEntry(insight, mappedTimelineEntries, taskEntryMap);
	mapLogEntriesToTimelineEntry(insight, mappedTimelineEntries, taskEntryMap, translate);

	return sortTimelineEntriesByCompletion(mappedTimelineEntries);
};

function mapTasksToTimelineEntry(
	insight: Insight,
	mappedTimelineEntries: MappedTimelineEntry[],
	taskEntryMap: { [key: string]: MappedTimelineEntry }
) {
	insight.tasks?.forEach(task => {
		const taskTimelineEntry = createTimelineEntryFromTask(task);
		taskEntryMap[task.id!] = taskTimelineEntry;
		mappedTimelineEntries.push(taskTimelineEntry);
	});
}

function mapLogEntriesToTimelineEntry(
	insight: Insight,
	mappedTimelineEntries: MappedTimelineEntry[],
	taskEntryMap: { [key: string]: MappedTimelineEntry },
	translate: TranslateFn
) {
	let lastTimelineEntry: MappedTimelineEntry | null = null;

	insight.logEntries?.forEach((logEntry, index) => {
		if (logEntry.taskId && taskEntryMap[logEntry.taskId]) {
			getLogsForTask(taskEntryMap[logEntry.taskId], logEntry);
		} else {
			lastTimelineEntry = processLogTimelineEntry(
				logEntry,
				lastTimelineEntry,
				mappedTimelineEntries,
				insight.logEntries,
				index,
				translate
			);
		}
	});
}

function getLogsForTask(taskTimelineEntry: MappedTimelineEntry, logEntry: InsightLogEntry) {
	taskTimelineEntry.logs.push({
		createdBy: logEntry.createdBy,
		message: logEntry.message,
		created: logEntry.created,
		type: logEntry.type,
		attachments: logEntry.attachments,
		editable: true
	});
}

function processLogTimelineEntry(
	logEntry: InsightLogEntry,
	lastTimelineEntry: MappedTimelineEntry | null,
	mappedTimelineEntries: MappedTimelineEntry[],
	logEntries: InsightLogEntry[],
	index: number,
	translate: TranslateFn
): MappedTimelineEntry {
	if (shouldGroupLogEntryWithPrevious(logEntry, lastTimelineEntry, logEntries, index)) {
		const { message, editable } = processLogByType(logEntry, translate);
		lastTimelineEntry!.logs.push({
			createdBy: logEntry.createdBy,
			message: message,
			created: logEntry.created,
			type: logEntry.type,
			attachments: logEntry.attachments,
			editable
		});
		lastTimelineEntry!.completedTime = logEntry.created;
	} else {
		const newTimelineEntry = createTimelineEntryFromLogEntry(logEntry, translate);
		mappedTimelineEntries.push(newTimelineEntry);
		return newTimelineEntry;
	}
	return lastTimelineEntry!;
}

function shouldGroupLogEntryWithPrevious(
	logEntry: InsightLogEntry,
	lastTimelineEntry: MappedTimelineEntry | null,
	logEntries: InsightLogEntry[],
	index: number
): boolean {
	return !!(
		lastTimelineEntry &&
		lastTimelineEntry.status === logEntry.status &&
		logEntry.reason === lastTimelineEntry.reason &&
		lastTimelineEntry.completedTime === logEntries[index - 1]?.created
	);
}

function sortTimelineEntriesByCompletion(mappedTimelineEntries: MappedTimelineEntry[]): MappedTimelineEntry[] {
	return mappedTimelineEntries.sort((a, b) => {
		if (a.completed && b.completed) {
			return new Date(a.completedTime).getTime() - new Date(b.completedTime).getTime();
		} else if (a.completed) {
			return -1;
		} else if (b.completed) {
			return 1;
		} else {
			return 0;
		}
	});
}

function createTimelineEntryFromTask(task: Task): MappedTimelineEntry {
	return {
		title: task.description,
		completed: !!task.completed,
		completedTime: task.completed ?? '',
		logs: [],
		id: task.id,
		createdBy: task.creatorId
	};
}

function createTimelineEntryFromLogEntry(logEntry: InsightLogEntry, translate: TranslateFn): MappedTimelineEntry {
	return {
		title: getLogLabel(logEntry, translate),
		completed: true,
		logs: [
			{
				created: logEntry.created,
				createdBy: logEntry.createdBy,
				message: processLogByType(logEntry, translate).message,
				attachments: logEntry.attachments,
				editable: logEntry.type !== LogEntryType.GENERAL
			}
		],
		completedTime: logEntry.created,
		createdBy: logEntry.createdBy,
		status: logEntry.status,
		reason: logEntry.reason
	};
}

function getLogLabel(log: InsightLogEntry, t: TranslateFn): string {
	const logTypeLabels: Partial<Record<LogEntryType, string>> = {
		[LogEntryType.APPROVAL_REJECTED]: t('eventModal.log-label.APPROVAL_REJECTED'),
		[LogEntryType.CAPEX_DECISION]: t('eventModal.log-label.CAPEX_DECISION'),
		[LogEntryType.PENDING_RESOLVED]: t('eventModal.log-label.PENDING_RESOLVED')
	};
	if (log.type && log.type in logTypeLabels) {
		return logTypeLabels[log.type] || '';
	}
	if (log.status === Status.OPEN || log.status === Status.NO_RESPONSE) {
		return t('general.created');
	}
	if (log.status === Status.PENDING && log.reason) {
		return `${t(`eventModal.${log.status}`)}: ${t(`eventModal.hold.${log.reason}` as TranslationKeys)}`;
	}
	return t(`eventModal.${log.status}`);
}

function processLogByType(log: InsightLogEntry, t: TranslateFn): { message: string; editable: boolean } {
	const logTypeLabels: Partial<Record<LogEntryType, string>> = {
		[LogEntryType.RESPONSIBLE_REASSIGNMENT]: t('eventModal.log-label.RESPONSIBLE_REASSIGNMENT'),
		[LogEntryType.SUPERVISOR_REASSIGNMENT]: t('eventModal.log-label.SUPERVISOR_REASSIGNMENT'),
		[LogEntryType.DUEDATE_REASSIGNMENT]: t('eventModal.log-label.DUEDATE_REASSIGNMENT'),
		[LogEntryType.EVIDENCE_UPDATED]: t('eventModal.log-label.EVIDENCE_UPDATED')
	};

	const isInLogTypeLabels = log.type && log.type in logTypeLabels;

	return {
		message: isInLogTypeLabels ? logTypeLabels[log.type!] || '' : log.message,
		editable: !isInLogTypeLabels
	};
}
