import { Insight, InsightHoldReason, LogEntryType } from '../../../../models/Insights';
import { HoldInsightForApproval } from './approval-flow/HoldInsightForApproval';
import { DeclineInsightWithoutApproval } from './DeclineInsightWithoutApproval';
import { useCheckInsightPermissions } from './useCheckInsightPermissions';

interface Props {
	modalOpen: boolean;
	handleModalClose: () => void;
	insight: Insight;
}

export function DeclineInsightModal({ modalOpen, handleModalClose, insight }: Props) {
	const { closeInsightDoesNotNeedOwnerApproval } = useCheckInsightPermissions(insight);

	return (
		<>
			{closeInsightDoesNotNeedOwnerApproval ? (
				<DeclineInsightWithoutApproval insight={insight} modalOpen={modalOpen} onClose={handleModalClose} />
			) : (
				<HoldInsightForApproval
					insight={insight}
					modalOpen={modalOpen}
					onClose={handleModalClose}
					reason={InsightHoldReason.SUPERVISOR_DECLINE_APPROVAL}
				/>
			)}
		</>
	);
}
