import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useState } from 'react';
import { Insight, InsightHoldReason } from '../../../../../models/Insights';
import { UserModel } from '../../../../../models/User';
import { InsightStateButton } from '../../../../buttons/InsightStateButton';
import { ApproveInsightDeclineAction } from './ApproveInsightDeclineAction';
import { RejectApprovalRequest } from './RejectApprovalRequest';
import { ApproveInsightRepairAction } from './ApproveInsightRepairAction';

interface Props {
	insight: Insight;
	currentUser: UserModel;
}

export function ResolveSupervisorApproval({ insight, currentUser }: Props) {
	const [modal, setModal] = useState<'approve' | 'reject'>();

	return (
		<>
			<InsightStateButton
				icon={<CheckCircleOutlineOutlinedIcon style={{ fontSize: '1.9rem' }} />}
				label="action.approve"
				onClick={() => setModal('approve')}
				color="#4F9572"
			/>
			<InsightStateButton
				icon={<BlockOutlinedIcon style={{ fontSize: '1.9rem' }} />}
				label="action.reject"
				onClick={() => setModal('reject')}
				color="#D63737"
			/>
			{modal === 'approve' && insight.latest?.reason === InsightHoldReason.SUPERVISOR_DECLINE_APPROVAL && (
				<ApproveInsightDeclineAction
					insight={insight}
					currentUser={currentUser}
					modalOpen={modal === 'approve'}
					onClose={() => setModal(undefined)}
				/>
			)}
			{modal === 'approve' && insight.latest?.reason === InsightHoldReason.SUPERVISOR_REPAIR_APPROVAL && (
				<ApproveInsightRepairAction
					insight={insight}
					currentUser={currentUser}
					modalOpen={modal === 'approve'}
					onClose={() => setModal(undefined)}
				/>
			)}
			{modal === 'reject' && (
				<RejectApprovalRequest
					insight={insight}
					currentUser={currentUser}
					modalOpen={modal === 'reject'}
					onClose={() => setModal(undefined)}
				/>
			)}
		</>
	);
}
