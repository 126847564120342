import { InsightModal } from '../../../../modal/insight/InsightModal';
import { useInsight } from '../../../../../stores/insights/InsightStore';
import { Insight, InsightSummaryModel } from '../../../../../models/Insights';

interface Props {
	id: string;
	onClose: () => void;
}

export function InsightModalWrapper({ id, onClose }: Props) {
	const { data } = useInsight(id);
	if (!data) return null;
	return <InsightModal data={toInsightSummaryModel(data)} open={true} onClose={onClose} />;
}

function toInsightSummaryModel(data: Insight): InsightSummaryModel {
	return {
		...data,
		responsible: data.latest?.responsibleEmail,
		created: (data.created as unknown) as string,
		premiseName: (data.premiseName as unknown) as string,
		premiseId: (data.premiseId as unknown) as string,
		priority: data.priority ?? 0
	};
}
