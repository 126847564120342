import { SupportedLocale } from '../../../../models/LocalizedString';
import { BillOfMaterialsEntry } from '../../model/InstallationJob';
import { InstallationWrapper } from '../../model/InstallationWrapper';
import { BomEntry } from './BomEntry';

interface Props {
	bom: BillOfMaterialsEntry[];
	lang: SupportedLocale;
	installation?: InstallationWrapper;
	handleRemoveBomEntry: (index: number) => void;
}

export function BomContainer({ bom, lang, installation, handleRemoveBomEntry }: Props) {
	return (
		<>
			{bom.map((entry, index) => (
				<BomEntry key={index} lang={lang} {...entry} onRemove={() => handleRemoveBomEntry(index)} index={index} />
			))}
		</>
	);
}
