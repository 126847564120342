import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Insight, Status } from '../../../../models/Insights';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { InsightStateButton } from '../../../buttons/InsightStateButton';

interface Props {
	insight: Insight;
	dataCy?: string;
}

export function StartInsight({ insight, dataCy }: Props) {
	const { startInsight } = useInsightActions();

	async function handleStartInsight() {
		await startInsight(insight.id);
	}

	return (
		<InsightStateButton
			icon={<PlayCircleOutlineIcon style={{ fontSize: '1.9rem' }} />}
			label="action.start"
			onClick={handleStartInsight}
			color="#5bf7bf"
			iconColor="#00195f"
			dataCy={dataCy}
		/>
	);
}
