import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { createUuid } from '../../../../hooks/useUuid';
import { SupportedLocale } from '../../../../i18n/getLocaleStrings';
import { TranslationKeys } from '../../../../locales/TranslationKeys';
import { Option } from '../../../../models/Option';
import { getLanguageOptions, localeMap } from '../../../../utils/i18nUtils';
import { BaseForm } from '../../../form/BaseForm';
import { FormId } from '../../../form/FormId';
import { InputLabelWithRequired } from '../../../form/InputLabelWithRequired';
import { ControlledSearchableSelect } from '../../../form/controls/ControlledSearchableSelect';
import { ControlledTextField } from '../../../form/controls/ControlledTextField';
import { MarkdownField } from '../../../form/controls/MarkdownField';
import { SingleOptionSelect } from '../../../form/controls/SingleOptionSelect';
import { useZodForm } from '../../../form/hooks/useZodForm';
import { Column, Row } from '../../../modal/modalUtils';
import { FreesiButton } from '../../../styled/FreesiButton';
import { GenericTab } from '../../../tabs/GenericTabs';
import { SimpleTabs } from '../../../tabs/SimpleTabs';
import {
	BillOfMaterialsEntry,
	getInstallationJobSchema,
	getJobTypeSchema,
	InstallationJob,
	InstallationJobStep
} from '../../model/InstallationJob';
import { JobMetadataField } from './JobMetadataField';
import { StepContainer } from './StepContainer';
import { InstallationWrapper } from '../../model/InstallationWrapper';
import { usePrepopulateJobFields } from './usePrepopulateJobFields';
import { BomContainer } from './BomContainer';

interface Props {
	onSubmit: (data: InstallationJob) => Promise<void>;
	defaultValues?: Partial<InstallationJob>;
	installation?: InstallationWrapper;
}

const emptyValues: Partial<InstallationJob> = {
	type: 'GENERIC',
	metadata: {},
	title: localeMap(() => ''),
	instructions: localeMap(() => ''),
	selectedLanguages: [],
	steps: [],
	premiseMarkingPrefix: ''
};

export function InstallationJobForm({ onSubmit, defaultValues = {}, installation }: Props) {
	const { t, unsafeTranslation } = useTypedTranslation();
	const [sortMode, setSortMode] = useState(false);
	const schema = getInstallationJobSchema(t);
	const form = useZodForm({
		schema,
		defaultValues: {
			id: createUuid(),
			...emptyValues,
			...defaultValues
		},
		mode: 'onTouched'
	});

	const { replace: setSteps } = useFieldArray({ control: form.control, name: 'steps' });
	const steps = form.watch('steps');

	const { replace: setBom } = useFieldArray({ control: form.control, name: 'billOfMaterials' });
	const bom = form.watch('billOfMaterials') ?? [];

	const [activeTab, setActiveTab] = useState<SupportedLocale>('en');
	const selectedLanguages = form.watch('selectedLanguages');

	usePrepopulateJobFields({ form, lang: activeTab });

	const tabErrorStyle = { color: 'red' };
	const tabs: GenericTab[] = [
		{
			id: 'en',
			translation: 'languages.name.en',
			style: shouldShowErrorStyle('en', activeTab, form.formState.errors) ? tabErrorStyle : undefined
		},
		...selectedLanguages.map(l => ({
			id: l,
			translation: `languages.name.${l}` as TranslationKeys,
			style: shouldShowErrorStyle(l, activeTab, form.formState.errors) ? tabErrorStyle : undefined
		}))
	];

	const typeOptions: Option[] = getJobTypeSchema(t).options.map(value => ({
		value,
		label: unsafeTranslation(`installation.jobs.types.${value}`)
	}));

	const lang = activeTab;

	function handleDragEnd(result: InstallationJobStep[]) {
		setSteps(result);
	}

	function handleRemoveStep(index: number) {
		setSteps([...steps.slice(0, index), ...steps.slice(index + 1)]);
	}

	function handleRemoveBomEntry(index: number) {
		setBom([...bom.slice(0, index), ...bom.slice(index + 1)]);
	}

	return (
		<BaseForm id={FormId.INSTALLATION_JOB} className="freesi-modal-form" onSubmit={onSubmit} form={form}>
			<Column gap="1.25rem">
				<Row justifyContent="space-between" alignItems="center">
					<SimpleTabs
						tabs={tabs}
						activeTab={activeTab}
						setActiveTab={tabName => setActiveTab(tabName as SupportedLocale)}
					/>
					<ControlledSearchableSelect
						name="selectedLanguages"
						multiple={true}
						options={getLanguageOptions(unsafeTranslation, true)}
						placeholder={t('notificationForm.add-language')}
						sx={{ width: '150px' }}
						disableTags
					/>
				</Row>
				<Row justifyContent="space-between">
					<Column style={{ maxWidth: '48%' }}>
						<InputLabelWithRequired label="general.form.type" required />
						<SingleOptionSelect name="type" options={typeOptions} />
					</Column>
					<Column style={{ maxWidth: '48%' }}>
						<InputLabelWithRequired label="general.title" required />
						<ControlledTextField name={`title.${lang}`} fullWidth={true} key={lang} />
					</Column>
				</Row>
				<JobMetadataField />
				<MarkdownField
					name={`instructions.${lang}`}
					key={'instructions' + lang}
					pdfUpload
					pictureUpload
					fileUploadParams={{
						container: 'installation',
						group: installation?.getId() ?? 'templates'
					}}
					rows={8}
					label={t('installation.instructions')}
					required
				/>
				<StepContainer
					onDragEnd={handleDragEnd}
					steps={steps}
					setSteps={setSteps}
					lang={lang}
					installation={installation}
					handleRemoveStep={handleRemoveStep}
					sortMode={sortMode}
				/>
				<div>
					<FreesiButton
						label="installation.jobs.form.addStep"
						onClick={() => {
							setSortMode(false);
							setSteps([...steps, createStep()]);
						}}
						margin={5}
					/>
					{steps.length > 1 && (
						<FreesiButton
							label="installation.sortSteps"
							onClick={() => setSortMode(true)}
							secondary={sortMode}
							margin={5}
						/>
					)}
				</div>
				<InputLabelWithRequired label="installation.jobs.billOfMaterials" />
				<BomContainer
					bom={bom ?? []}
					lang={lang}
					installation={installation}
					handleRemoveBomEntry={handleRemoveBomEntry}
				/>

				<div>
					<FreesiButton label="general.addEntry" onClick={() => setBom([...bom, createBomEntry()])} margin={5} />
				</div>
			</Column>
		</BaseForm>
	);
}

export function createStep(): InstallationJob['steps'][0] {
	return {
		type: 'GENERIC',
		title: localeMap(() => ''),
		instructions: localeMap(() => ''),
		uuid: createUuid()
	};
}

export function createBomEntry(): BillOfMaterialsEntry {
	return {
		title: localeMap(() => ''),
		amount: 1
	};
}

function shouldShowErrorStyle(lang: SupportedLocale, activeTab: string, errors: any) {
	if (lang === activeTab) return false;

	return (
		errors?.title?.[lang] ||
		errors?.instructions?.[lang] ||
		errors?.steps?.some((s: any) => s?.title?.[lang] || s?.instructions?.[lang])
	);
}
