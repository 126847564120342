import { PersonOutline } from '@mui/icons-material';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight } from '../../../../models/Insights';
import { UserHeader } from '../../../../models/User';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { UserInstantSearch } from '../../../user-search/UserInstantSearch';
import { Row } from '../../modalUtils';
import { iconStyle, InsightText } from '../InsightModal.styles';

interface Props {
	insight: Insight;
	disabled: boolean;
}

export function InsightSupervisorRow({ insight, disabled }: Props) {
	const { t } = useTypedTranslation();
	const { updateInsightDetails } = useInsightActions();

	const supervisor = insight.supervisor;

	const debouncedUpdateSupervisor = useCallback(
		debounce((supervisorId: string) => {
			updateInsightDetails(insight.id, { supervisor: supervisorId });
		}, 1000),
		[insight.id]
	);

	function handleChangeSupervisor(value: UserHeader | string) {
		if (!supervisor) return;

		let supervisorId = supervisor?.id;
		if (typeof value === 'string') {
			return;
		} else if (supervisorId !== value.id) {
			supervisorId = value.id;
			debouncedUpdateSupervisor(supervisorId);
		}
	}

	return (
		<div style={{ width: 'fit-content' }}>
			<InsightText>{t('eventModal.supervisor')}</InsightText>
			<Row alignItems="center" style={{ minWidth: '150px' }}>
				<PersonOutline sx={iconStyle} />
				<UserInstantSearch
					onChange={handleChangeSupervisor}
					getOptionLabel={o => o.fullName}
					defaultValue={insight.supervisor?.fullName}
					disabled={disabled}
				/>
			</Row>
		</div>
	);
}
