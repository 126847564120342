import { useState } from 'react';
import { DynamicTextField } from '../../../dynamic-textfields/DynamicTextFields';
import { FreesiModal } from '../../FreesiModal';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { Insight } from '../../../../models/Insights';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { UserModel } from '../../../../models/User';

interface Props {
	insight: Insight;
	currentUser: UserModel;
	modalOpen: boolean;
	onClose: () => void;
}

export function AddTasksModal({ insight, modalOpen, onClose, currentUser }: Props) {
	const { t } = useTypedTranslation();
	const { addTasksToInsight } = useInsightActions();
	const [tasks, setTasks] = useState<string[]>(['']);

	async function handleAddTasks() {
		const newTasks = tasks
			.filter(description => description !== '')
			.map(description => ({
				description,
				creatorId: currentUser.id
			}));

		await addTasksToInsight(insight.id, newTasks);
		setTasks(['']);
		onClose();
	}

	return (
		<FreesiModal
			open={modalOpen}
			maxWidth="sm"
			title={t('eventModal.tasks.add-tasks')}
			cancelable
			submittable
			onSubmit={handleAddTasks}
			onCancel={onClose}
			submitButtonTitle={t('action.save')}>
			<DynamicTextField fields={tasks} setFields={setTasks} label={t('eventModal.tasks.tasks')} />
		</FreesiModal>
	);
}
