import { Stepper } from '@mui/material';
import { useState } from 'react';
import { Insight } from '../../../../models/Insights';
import { useCurrentUser } from '../../../../stores/session/sessionHelperHooks';
import DelaySpinner from '../../../delay-spinner/DelaySpinner';
import { Column } from '../../modalUtils';
import { CompleteTaskModal } from '../task/CompleteTaskModal';
import { DeleteTaskModal } from '../task/DeleteTaskModal';
import { AddTasksStep } from './AddTasksStep';
import { TimelineEntry } from './TimelineEntry';
import { MappedTimelineEntry, mapTasksAndLogs } from './mapTasksAndLog';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { ViewAttachmentsModal } from './ViewAttachmentsModal';
import { useCheckInsightPermissions } from '../process-insight/useCheckInsightPermissions';
import { EditLogEntryModal } from '../log-entry/EditLogEntryModal';

interface Props {
	insight: Insight;
	canProcessInsight: boolean;
}

type Modal = 'add-tasks' | 'complete-task' | 'delete-task' | 'view-attachments' | 'edit-log';

export function InsightTimeline({ insight, canProcessInsight }: Props) {
	const { unsafeTranslation } = useTypedTranslation();
	const { hasSupervisorPermission } = useCheckInsightPermissions(insight);
	const currentUser = useCurrentUser();
	const [modal, setModal] = useState<Modal | undefined>();
	const [selectedTask, setSelectedTask] = useState<MappedTimelineEntry>();
	const [attachments, setAttachments] = useState<string[]>([]);
	const [selectedLogTimestamp, setSelectedLogTimestamp] = useState<string>();

	function onCommentSelected(created: string) {
		setSelectedLogTimestamp(created);
		setModal('edit-log');
	}

	const selectedLogEntry = insight.logEntries.find(e => e.created === selectedLogTimestamp);
	// TODO: might need to consider anonymous user here
	const canEditLogEntry =
		selectedLogEntry &&
		modal === 'edit-log' &&
		canProcessInsight &&
		currentUser?.fullName === selectedLogEntry.createdBy;

	const mappedTimeline = mapTasksAndLogs(insight, unsafeTranslation);

	function handleModalClose() {
		setModal(undefined);
		setSelectedTask(undefined);
		setAttachments([]);
	}

	function handleCompleteTask(entry: MappedTimelineEntry) {
		setModal('complete-task');
		setSelectedTask(entry);
	}

	function handleDeleteTask(entry: MappedTimelineEntry) {
		setModal('delete-task');
		setSelectedTask(entry);
	}

	function handleOpenAttachments(attachments: string[]) {
		setModal('view-attachments');
		setAttachments(attachments);
	}

	if (!currentUser) return <DelaySpinner />;

	return (
		<>
			<Column>
				<Stepper orientation="vertical">
					{mappedTimeline.map((entry, index) => (
						<TimelineEntry
							key={index}
							entry={entry}
							insightStatus={insight.status}
							onComplete={() => handleCompleteTask(entry)}
							onDelete={() => handleDeleteTask(entry)}
							canProcessInsight={canProcessInsight}
							onOpenAttachment={handleOpenAttachments}
							currentUser={currentUser}
							onCommentSelected={onCommentSelected}
						/>
					))}
					{canProcessInsight && hasSupervisorPermission && (
						<AddTasksStep
							onAddTask={() => setModal('add-tasks')}
							modalOpen={modal === 'add-tasks'}
							onClose={handleModalClose}
							insight={insight}
							currentUser={currentUser}
						/>
					)}
				</Stepper>
			</Column>
			{selectedTask && canProcessInsight && (
				<>
					<CompleteTaskModal
						insight={insight}
						currentUser={currentUser}
						task={selectedTask}
						modalOpen={modal === 'complete-task'}
						onClose={handleModalClose}
					/>
					<DeleteTaskModal
						modalOpen={modal === 'delete-task'}
						onClose={handleModalClose}
						insight={insight}
						task={selectedTask}
					/>
				</>
			)}
			<ViewAttachmentsModal
				attachments={attachments}
				modalOpen={modal === 'view-attachments'}
				onClose={handleModalClose}
			/>
			{canEditLogEntry && (
				<EditLogEntryModal
					selectedLogEntry={selectedLogEntry}
					insight={insight}
					onClose={handleModalClose}
					open={modal === 'edit-log' && !!selectedLogEntry}
				/>
			)}
		</>
	);
}
