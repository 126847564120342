import { useState } from 'react';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { useWithFallbackContent } from '../../../../hooks/useWithFallbackContent';
import { useInstallationJobTemplateActions } from '../../../../stores/installation/InstallationJobTemplatesStore';
import { pageSizeFrom } from '../../../../views/control-panel/ControlPanelView';
import { FormId } from '../../../form/FormId';
import { ColumnType, GenericTable } from '../../../generictable/GenericTable';
import { KebabMenu } from '../../../kebab-menu/KebabMenu';
import { ConfirmModal } from '../../../modal/ConfirmModal';
import { FreesiModal } from '../../../modal/FreesiModal';
import { InstallationJob } from '../../model/InstallationJob';
import { InstallationJobForm } from '../form/InstallationJobForm';
import { CreateJobFromTemplate } from './CreateJobFromTemplate';
import { TemplateGroupHeader } from './TemplateGroupHeader';

interface Props {
	type: string;
	templates: InstallationJob[];
	isStandAlone: boolean;
}

interface TemplateModal {
	type: 'edit' | 'clone' | 'delete' | 'createJob';
	id: string;
	data: InstallationJob;
}

export function TemplatesGroup({ type, templates, isStandAlone }: Props) {
	const { t } = useTypedTranslation();
	const { deleteJobTemplate, editJobTemplate, createJobTemplate } = useInstallationJobTemplateActions();
	const withFallback = useWithFallbackContent();
	const { windowHeight } = useWindowResize();
	const isMobile = useIsMobile();
	const [open, setOpen] = useState(true);
	const [modal, setModal] = useState<TemplateModal | undefined>();
	const columns: ColumnType<InstallationJob>[] = [
		{
			name: 'name',
			label: 'general.form.name',
			width: '70%',
			columnRenderer: t => withFallback(t.title)
		},
		{
			name: 'actions',
			label: 'action.add',
			width: '30%',
			columnRenderer: t => (
				<div style={{ display: 'flex' }}>
					{!isStandAlone && (
						<CreateJobFromTemplate template={t} isMobile={isMobile} closeModal={() => setModal(undefined)} />
					)}
					<KebabMenu
						options={[
							{
								label: 'installation.editInstallationJobTemplate',
								onSelect: () => setModal({ id: t.id, type: 'edit', data: t })
							},
							{
								label: 'installation.cloneInstallationJobTemplate',
								onSelect: () => setModal({ id: t.id, type: 'clone', data: t })
							},
							{
								label: 'installation.deleteInstallationJobTemplate',
								onSelect: () => setModal({ id: t.id, type: 'delete', data: t })
							}
						]}
					/>
				</div>
			)
		}
	];

	return (
		<div style={{ minWidth: isMobile ? 0 : 800 }}>
			<TemplateGroupHeader type={type} open={open} setOpen={setOpen} />
			{open && (
				<GenericTable
					columns={columns}
					rows={templates}
					rowIdGetter={t => t.id}
					hideHeader
					maxItemsPerPage={pageSizeFrom(windowHeight)}
				/>
			)}
			<ConfirmModal
				open={modal?.type === 'delete'}
				onClose={() => setModal(undefined)}
				onConfirm={async () => modal && deleteJobTemplate(modal?.id)}
				title={t('installation.deleteInstallationJobTemplate')}
				body={t('general.confirmIrreversibleAction')}
				confirmButtonText="general.remove"
			/>
			<FreesiModal
				open={modal?.type === 'edit'}
				onCancel={() => setModal(undefined)}
				cancelable
				title={t('installation.editInstallationJobTemplate')}
				maxWidth="md"
				submittable
				formId={FormId.INSTALLATION_JOB}
				submitButtonTitle={t('general.submit')}>
				<InstallationJobForm
					onSubmit={async data => {
						if (modal) await editJobTemplate(modal.id, data);
						setModal(undefined);
					}}
					defaultValues={modal?.data}
				/>
			</FreesiModal>
			<FreesiModal
				open={modal?.type === 'clone'}
				onCancel={() => setModal(undefined)}
				cancelable
				title={t('installation.cloneInstallationJobTemplate')}
				maxWidth="md"
				formId={FormId.INSTALLATION_JOB}
				submitButtonTitle={t('general.submit')}>
				<InstallationJobForm
					onSubmit={async data => {
						if (modal) await createJobTemplate(data);
						setModal(undefined);
					}}
					defaultValues={withoutId(modal?.data)}
				/>{' '}
			</FreesiModal>
		</div>
	);
}

export function withoutId(data?: InstallationJob) {
	if (!data) return undefined;
	const { id, ...others } = data;
	return others;
}
