import { SensorInfo, SensorModel } from '../../models/SensorInfo';
import { MeasurementType } from '../../models/Measurement';
import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { getSnackbar } from '../snackbar/SnackbarHelper';
import { sensorsApiFactory } from '../../api/sensorsApi';
import { DataStoreAccessor } from '../utils/genericdatastore/DataStoreAccessor';
import { DataStoreState } from '../utils/genericdatastore/DataStoreState';
import { loadingState } from '../utils/loadingState';
import { SearchResults } from '../utils/genericdatastore/SearchResults';
import { StoredData } from '../utils/genericdatastore/StoredData';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { isDevMode } from '../../test/utils/isDevMode';
import { SearchParams } from '../../models/Sensor';
import { isEmpty } from 'lodash';
import { getInvalidationState, InvalidationState } from '../utils/InvalidationState';

type State = {} & InvalidationState;
type SensorDataState = DataStoreState<string, string, string, SensorInfo>;

export type SensorActions = {
	locateSensor: (sensor: SensorInfo, locationId: string, premiseId: string) => Promise<void>;
	setAutomationSensorType: (sensor: SensorInfo, measurementType: MeasurementType) => Promise<void>;
	unbindSensor: (sensor: SensorInfo) => Promise<void>;
	updateSensors: (sensorsIds: string[]) => Promise<void>;
	invalidateLocationSensors: (locationId: string) => Promise<void>;
};

export type InternalActions = {
	fetchSensor: (id: string, suppressErrors?: boolean) => Promise<void>;
	fetchLocationSensors: (locationId: string) => Promise<void>;
	fetchPremiseSensors: (locationId: string, premiseId: string) => Promise<void>;
	searchSensorsByParams: (searchParams: SearchParams) => Promise<void>;
	resetSearch: () => void;
};

type SensorStore = State & SensorDataState & SensorActions & InternalActions;

const useZustand = create<SensorStore>((set, get) => {
	const ds = new DataStoreAccessor(get, set, sensorsApiFactory, (s: SensorInfo) => s.sensorId);
	return {
		items: new Map(),
		search: {},
		lists: new Map(),
		async searchSensorsByParams(searchParams: SearchParams) {
			try {
				const searchKey = JSON.stringify(searchParams);
				await ds.doSearch(searchKey, api => api.searchSensors(searchParams));
			} catch (error) {
				console.error(error);
				getSnackbar().showError('sensors-view.snackbarMessages.search-error');
			}
		},
		async fetchLocationSensors(locationId: string) {
			try {
				await ds.doFetchList(locationId, api => api.getLocationSensors(locationId));
			} catch (error) {
				console.error(error);
				getSnackbar().showError('sensors-view.snackbarMessages.find-by-location-error');
			}
		},
		async fetchPremiseSensors(locationId: string, premiseId: string) {
			try {
				await ds.doFetchList(premiseId, api => api.getPremiseSensors(locationId, premiseId));
			} catch (error) {
				console.error(error);
			}
		},
		async locateSensor(sensor: SensorInfo, locationId: string, premiseId: string) {
			const snackbar = getSnackbar();
			snackbar.showInfo('sensors-view.snackbarMessages.locate-info');
			try {
				await ds.doMutatingAction(sensor.sensorId, api => api.locateSensor(locationId, premiseId, sensor.sensorId));
				snackbar.showSuccess('sensors-view.snackbarMessages.locate-success');
			} catch (error) {
				console.error(error);
				snackbar.showError('sensors-view.snackbarMessages.locate-error');
			}
		},
		async setAutomationSensorType(sensor: SensorInfo, measurementType: MeasurementType) {
			const snackbar = getSnackbar();
			snackbar.showInfo('sensors-view.snackbarMessages.set-type-info');
			try {
				await ds.doMutatingAction(sensor.sensorId, api =>
					api.setAutomationSensorType(sensor.sensorId, measurementType)
				);
				snackbar.showSuccess('sensors-view.snackbarMessages.set-type-success');
			} catch (error) {
				console.error(error);
				snackbar.showError('sensors-view.snackbarMessages.set-type-error');
			}
		},
		async unbindSensor({ sensorId }: SensorInfo) {
			const snackbar = getSnackbar();
			snackbar.showInfo('sensors-view.snackbarMessages.unbind-info');
			try {
				await ds.doMutatingAction(sensorId, async api => {
					await api.unbindSensor(sensorId);
					return api.getSensorInfo(sensorId);
				});
				snackbar.showSuccess('sensors-view.snackbarMessages.unbind-success');
			} catch (error) {
				console.error(error);
				snackbar.showError('sensors-view.snackbarMessages.unbind-error');
			}
		},
		async fetchSensor(sensorId: string, suppressErrors) {
			try {
				await ds.doFetchItem(sensorId, api => api.getSensorInfo(sensorId));
			} catch (error) {
				console.error(error);
				if (!suppressErrors) getSnackbar().showDefaultError(error);
			}
		},
		async updateSensors(sensorIds: string[]) {
			await Promise.all(sensorIds.map(sensorId => ds.doFetchItem(sensorId, api => api.getSensorInfo(sensorId))));
		},
		async invalidateLocationSensors(locationId: string) {
			/*
      // sensors for location only have to be refreshed when loaded previously
      if (get().lists?.get(locationId)) {
        await get().fetchLocationSensors(locationId);
        set({ ...getInvalidationState() });
      }*/
			get().invalidateAll();
		},
		invalidateAll() {
			set({ ...getInvalidationState(), lists: new Map(), items: new Map(), search: {} });
		},
		resetSearch() {
			set({ search: {} });
		}
	};
});

export function useSensorActions() {
	return useZustand() as SensorActions;
}

export function useSensorSearch(searchParams: SearchParams): SearchResults<string, SensorInfo> {
	const { search, searchSensorsByParams, resetSearch, invalidated } = useZustand();
	useEffect(() => {
		if (Object.values(searchParams).some(param => !isEmpty(param))) {
			void searchSensorsByParams(searchParams);
		} else resetSearch();
	}, [searchParams, searchSensorsByParams, resetSearch, invalidated]);
	return search ?? loadingState;
}

export function useSensorSearchCallBack() {
	const { searchSensorsByParams, resetSearch } = useZustand();
	const getState = useZustand.getState;

	const triggerSearch = useCallback(
		async (searchParams: SearchParams) => {
			if (Object.values(searchParams).some(param => !!param)) {
				await searchSensorsByParams(searchParams);
				const updatedSearch = getState().search;
				return updatedSearch;
			} else {
				resetSearch();
				return getState().search;
			}
		},
		[searchSensorsByParams, resetSearch]
	);

	return { triggerSearch };
}

export function clearSensorSearch() {
	return useZustand.getState().resetSearch();
}

export function useSensorsByLocation(locationId: string, showUnlocated: boolean = false): StoredData<SensorInfo[]> {
	const { fetchLocationSensors, lists, invalidated } = useZustand();
	useEffect(() => {
		void fetchLocationSensors(locationId);
	}, [locationId, fetchLocationSensors, invalidated]);
	const state = lists?.get(locationId);
	if (!state) return loadingState;
	if (showUnlocated) return state;
	return {
		...state,
		data: state.data?.filter(s => s.premiseId && s.measurementTypes)
	};
}

export function useSensorsByPremise(locationId: string, premiseId: string): StoredData<SensorModel[]> {
	const { fetchPremiseSensors, lists, invalidated } = useZustand();
	useEffect(() => {
		void fetchPremiseSensors(locationId, premiseId);
	}, [locationId, premiseId, fetchPremiseSensors, invalidated]);
	const state = lists?.get(premiseId);
	if (!state) return loadingState;
	return {
		...state,
		data: state.data?.filter(s => s.premiseId)
	};
}

export function getSensorStore() {
	return useZustand.getState();
}

export function useSensor(sensorId: string, suppressErrors = false): StoredData<SensorInfo> {
	const { fetchSensor, invalidated, items } = useZustand();
	useEffect(() => {
		void fetchSensor(sensorId, suppressErrors);
	}, [sensorId, fetchSensor, invalidated]);
	return items?.get(sensorId) ?? loadingState;
}

if (isDevMode()) mountStoreDevtool('SensorStore', useZustand);
