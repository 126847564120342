import {
	Approval,
	CapexRequest,
	Evidence,
	Insight,
	InsightHandleRequest,
	InsightLogEntry,
	InsightResponsibleModel,
	InsightSummaryResponse,
	ManualInsightRequest,
	Status,
	Task,
	UpdateInsightDetailsDTO
} from '../models/Insights';
import { FetchParams } from '../models/sharedTypes';
import { freesiAxiosFactory } from './freesiAxiosFactory';
import { basicGet, basicPost, basicPut, deleteWithBodyRequest } from './utils/basicRestOperations';

export function insightsApiFactory(token: string | undefined) {
	const axios = freesiAxiosFactory('cloud', token);
	return {
		get: (params: FetchParams) => basicGet<InsightSummaryResponse>(axios, `/insights/paged`, params),
		getLocationEvents: (locationId: string, params: FetchParams) =>
			basicGet<InsightSummaryResponse>(axios, `/insights/locations/${locationId}/paged`, params),
		getPremiseEvents: (locationId: string, premiseId: string, params: FetchParams) =>
			basicGet<InsightSummaryResponse>(axios, `/insights/locations/${locationId}/premises/${premiseId}/paged`, params),
		getSingleInsight: (insightId: string) => basicGet<Insight>(axios, `/insights/${insightId}`),
		updateInsightLogEntry: (insightId: string, value: InsightLogEntry) =>
			basicPut<Status>(axios, `/insights/${insightId}/logEntries`, value),
		createManualInsight: (request: ManualInsightRequest) => basicPost<string>(axios, `/insights`, request),
		addLogEntryAsComment: (insightId: string, logEntry: InsightLogEntry) =>
			basicPost<void>(axios, `/insights/${insightId}/logEntries`, logEntry),
		addTasksToInsight: (insightId: string, tasks: Task[]) =>
			basicPut<void>(axios, `/insights/${insightId}/tasks`, tasks),
		completeSingleTask: (insightId: string, taskId: string, logEntry: InsightLogEntry) =>
			basicPost<void>(axios, `/insights/${insightId}/tasks/${taskId}/completion`, logEntry),
		removeSingleTask: (insightId: string, taskId: string) =>
			basicPost<void>(axios, `/insights/${insightId}/tasks/${taskId}/deletion`),
		addInsightEvidence: (insightId: string, evidence: Evidence) =>
			basicPost<void>(axios, `/insights/${insightId}/evidence`, evidence),
		updateInsightDetails: (insightId: string, values: UpdateInsightDetailsDTO) =>
			basicPut<void>(axios, `/insights/${insightId}/details`, values),
		getResponsibleOptions: () => basicGet<InsightResponsibleModel[]>(axios, `/insights/responsible`),
		startInsight: (insightId: string) => basicPost<string>(axios, `/insights/${insightId}/start`),
		completeInsight: (insightId: string, request: InsightHandleRequest) =>
			basicPost<string>(axios, `/insights/${insightId}/completion`, request),
		pauseInsight: (insightId: string, logEntry: InsightLogEntry) =>
			basicPost<string>(axios, `/insights/${insightId}/pause`, logEntry),
		resumePendingInsight: (insightId: string, request: InsightHandleRequest) =>
			deleteWithBodyRequest<string>(axios, `/insights/${insightId}/pause`, request),
		recordApprovalForInsightCompletion: (insightId: string, approval: Approval) =>
			basicPost<void>(axios, `/insights/${insightId}/approval`, approval),
		sendCAPEX: (insightId: string, CAPEX: CapexRequest) => basicPut<void>(axios, `/insights/${insightId}/CAPEX`, CAPEX)
	};
}

export type InsightsApi = ReturnType<typeof insightsApiFactory>;
