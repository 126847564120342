import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight, LogEntryType } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { useSnackbar } from '../../../../stores/snackbar/SnackbarHelper';
import { LabelValueRow } from '../LabelValueRow';
import { ProcessInsightModal } from '../process-insight/ProcessInsightModal';
import { MappedTimelineEntry } from '../timeline/mapTasksAndLog';

interface Props {
	insight: Insight;
	currentUser: UserModel;
	task: MappedTimelineEntry;
	modalOpen: boolean;
	onClose: () => void;
}

export function CompleteTaskModal({ insight, currentUser, task, modalOpen, onClose }: Props) {
	const { t } = useTypedTranslation();
	const { completeSingleTask } = useInsightActions();
	const snackbar = useSnackbar();

	async function completeTask(message: string, attachments: string[]) {
		if (!task.id) {
			snackbar.showError('error.no-details');
			return;
		}
		var logEntry = {
			created: new Date().toISOString(),
			createdBy: currentUser.id,
			type: LogEntryType.TASK_COMPLETED,
			status: insight.status,
			message: message,
			attachments: attachments,
			taskId: task.id
		};

		await completeSingleTask(insight.id, task.id, logEntry);
		onClose();
	}

	return (
		<ProcessInsightModal
			open={modalOpen}
			onClose={onClose}
			modalLabel="eventModal.tasks.complete-task"
			onSubmit={completeTask}
			messageLabel="eventModal.tasks.actions"
			extraChildren={<LabelValueRow label={t('eventModal.tasks.task')} value={task.title} />}
		/>
	);
}
