import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';
import { SupportedLocale } from '../../../../models/LocalizedString';
import { AccordionContainer } from '../../../accordion/AccordionContainer';
import { InstallationJobStep } from '../../model/InstallationJob';
import { JobStepFields } from './JobStepFields';
import { InstallationWrapper } from '../../model/InstallationWrapper';
import { SortableList } from '../../../sortable-list/SortableList';

interface Props {
	onDragEnd: (result: InstallationJobStep[]) => void;
	steps: InstallationJobStep[];
	lang: SupportedLocale;
	installation?: InstallationWrapper;
	handleRemoveStep: (index: number) => void;
	sortMode: boolean;
	setSteps: (steps: InstallationJobStep[]) => void;
}

export function StepContainer({ onDragEnd, steps, lang, installation, handleRemoveStep, sortMode, setSteps }: Props) {
	return (
		<SortableList
			items={steps}
			setItems={setSteps}
			canSort={sortMode}
			renderItem={(step, index) => (
				<AccordionContainer
					expand={!sortMode}
					sortMode={sortMode}
					title={index + 1 + '. ' + (step.title[lang] ?? '')}
					style={{ backgroundColor: '#f5f5f8', marginBottom: '1rem' }}
					action={
						<IconButton onClick={() => handleRemoveStep(index)}>
							<DeleteOutlineIcon sx={{ fontSize: '28px', color: 'red' }} />
						</IconButton>
					}>
					<JobStepFields stepNumber={index} lang={lang} steps={steps} installation={installation} />
				</AccordionContainer>
			)}
			onChange={onDragEnd}
			keyGetter={(item, index) => item.uuid ?? index.toString()}
		/>
	);
}
