import { Insight, InsightHoldReason } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { ResolveSupervisorApproval } from './approval-flow/ResolveSupervisorApproval';
import { MoreActions } from './MoreActions';
import { ResolveCAPEX } from './ResolveCAPEX';
import { ResumePendingInsight } from './ResumePendingInsight';

interface Props {
	insight: Insight;
	currentUser: UserModel;
}

export function ResolvePending({ insight, currentUser }: Props) {
	const latest = insight.latest;

	const insightIsHoldWithCAPEX = latest && latest.reason === InsightHoldReason.CAPEX.toString();
	const insightIsHoldWithFollowUp = latest && latest.reason === InsightHoldReason.FOLLOWUP.toString();
	const insightIsHoldForSupervisorApproval =
		latest &&
		(latest.reason === InsightHoldReason.SUPERVISOR_DECLINE_APPROVAL ||
			latest.reason === InsightHoldReason.SUPERVISOR_REPAIR_APPROVAL);

	return (
		<>
			{insightIsHoldWithCAPEX ? (
				<ResolveCAPEX insight={insight} currentUser={currentUser} dataCy="resolve-capex" />
			) : insightIsHoldForSupervisorApproval ? (
				<ResolveSupervisorApproval insight={insight} currentUser={currentUser} />
			) : (
				<>
					<ResumePendingInsight
						insight={insight}
						includeAddTasks={insightIsHoldWithFollowUp}
						dataCy="resolve-pending-insight"
					/>
					<MoreActions insight={insight} currentUser={currentUser} />
				</>
			)}
		</>
	);
}
