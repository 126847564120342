import { isNil } from 'lodash';
import { SensorInfo } from '../../models/SensorInfo';
import { InstallationAcceptance } from './InstallationAcceptance';

export class InstallationAcceptanceWrapper {
	readonly validation: InstallationAcceptance;

	constructor(validation: InstallationAcceptance) {
		this.validation = validation;
	}

	findFailedSensorBinds() {
		const failedSensorBinds = this.validation.areas
			.map(a => a.premises.map(p => p.sensorBinds.filter(s => s.status === 'ERROR_SENSOR_MISSING')))
			.flat(2);
		return failedSensorBinds.map(s => s.sensorId) ?? [];
	}

	updateSensorBinds(sensors: SensorInfo[]) {
		this.validation.areas.forEach(area => {
			area.premises.forEach(premise => {
				premise.sensorBinds.forEach(sensorBind => {
					const validSensor = findValidSensor(sensorBind, sensors);
					if (validSensor) {
						rebindSensor(sensorBind, validSensor);
					}
				});
			});
		});
	}
}

function findValidSensor(sensorBind: any, sensors: SensorInfo[]): SensorInfo | null {
	const matchingSensors = sensors.filter(s => s.sensorId === sensorBind.sensorId);
	// the found sensor need to be exact match
	if (matchingSensors.length === 1) {
		const foundSensor = matchingSensors[0];
		// the found sensor need to be unbound
		if (isNil(foundSensor.premiseId)) {
			return foundSensor;
		}
	}
	return null;
}

function rebindSensor(sensorBind: any, sensor: SensorInfo) {
	sensorBind.status = 'READY';
	sensorBind.sensorId = sensor.sensorId;
}
