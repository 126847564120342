import { useForceRemount } from '../../../../hooks/useForceRemount';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { SupportedLocale } from '../../../../i18n/getLocaleStrings';
import { Option } from '../../../../models/Option';
import { ControlledTextField } from '../../../form/controls/ControlledTextField';
import { MarkdownField } from '../../../form/controls/MarkdownField';
import { SingleOptionSelect } from '../../../form/controls/SingleOptionSelect';
import { InputLabelWithRequired } from '../../../form/InputLabelWithRequired';
import { Column, Row } from '../../../modal/modalUtils';
import { getJobStepTypeSchema, InstallationJob, InstallationJobStep } from '../../model/InstallationJob';
import { StepMetadataField } from './StepMetadataField';
import { useDefaultFieldContent } from './useDefaultFieldContent';
import { useFormContext } from 'react-hook-form';
import { InstallationWrapper } from '../../model/InstallationWrapper';

interface Props {
	installation?: InstallationWrapper;
	lang: SupportedLocale;
	stepNumber: number;
	steps: InstallationJobStep[];
}

export function JobStepFields({ stepNumber, lang, steps, installation }: Props) {
	const { t, unsafeTranslation } = useTypedTranslation();
	const form = useFormContext<InstallationJob>();
	useDefaultFieldContent({
		form,
		lang,
		watchedName: `steps.${stepNumber}.type`,
		changedName: `steps.${stepNumber}.title`,
		isStep: true
	});

	// [[kludge]] hook forms is acting up, forcing it to remount things when selected step changes
	const canMount = useForceRemount([stepNumber]);
	if (!canMount) return null;
	const stepTypeOptions: Option[] = getJobStepTypeSchema(t).options.map(value => ({
		value,
		label: unsafeTranslation(`installation.jobs.stepTypes.${value}`)
	}));

	return (
		<Column gap="1rem">
			<Row justifyContent="space-between" alignItems="flex-start">
				<Column style={{ maxWidth: '48%' }}>
					<InputLabelWithRequired label="general.form.type" required />
					<SingleOptionSelect name={`steps.${stepNumber}.type`} options={stepTypeOptions} />
				</Column>
				<Column style={{ maxWidth: '48%' }}>
					<InputLabelWithRequired label="general.title" required />
					<ControlledTextField
						name={`steps.${stepNumber}.title.${lang}`}
						fullWidth
						key={lang + steps[stepNumber].uuid}
					/>
				</Column>
			</Row>
			<StepMetadataField stepNumber={stepNumber} />
			<MarkdownField
				name={`steps.${stepNumber}.instructions.${lang}`}
				key={'instructions' + lang + steps[stepNumber].uuid}
				pdfUpload
				pictureUpload
				fileUploadParams={{
					group: installation?.getId() ?? 'templates',
					container: 'installation'
				}}
				label={t('installation.instructions')}
			/>
		</Column>
	);
}
