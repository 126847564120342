import { groupBy } from 'lodash';
import { useState } from 'react';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import {
	useInstallationJobTemplateActions,
	useInstallationJobTemplates
} from '../../../../stores/installation/InstallationJobTemplatesStore';
import { StyledSearchBar, TotalText } from '../../../control-room/ControlRoom.styles';
import { FormId } from '../../../form/FormId';
import { FreesiModal } from '../../../modal/FreesiModal';
import { FreesiButton } from '../../../styled/FreesiButton';
import { InstallationJob } from '../../model/InstallationJob';
import { InstallationJobForm } from '../form/InstallationJobForm';
import { TemplatesGroup } from './TemplatesGroup';
import { useInTotalPhrase } from '../../../../utils/i18nUtils';

interface Props {
	/**
	 * since templates list does not belong to any installation
	 * it should be be extendatble to access in installation context
	 * and stand alone as it is
	 */
	isStandAlone: boolean;
}

export function InstallationJobTemplates({ isStandAlone }: Props) {
	const { t } = useTypedTranslation();
	const { createJobTemplate } = useInstallationJobTemplateActions();
	const { data } = useInstallationJobTemplates();
	const templates = groupBy(data, t => t.type);
	const inTotal = useInTotalPhrase('installation.jobs.template', 'installation.jobTemplate_plural');

	const [modalOpen, setModalOpen] = useState(false);

	async function onSubmit(data: InstallationJob) {
		await createJobTemplate(data);
		setModalOpen(false);
	}

	return (
		<>
			<StyledSearchBar>
				<TotalText>{inTotal(data?.length ?? 0)}</TotalText>
				<FreesiButton label="installation.addJobTemplates" onClick={() => setModalOpen(true)} />
			</StyledSearchBar>
			{Object.keys(templates).map(k => (
				<TemplatesGroup type={k} key={k} templates={templates[k]} isStandAlone={isStandAlone} />
			))}
			<FreesiModal
				open={modalOpen}
				onCancel={() => setModalOpen(false)}
				cancelable
				submittable
				maxWidth="md"
				title={t('installation.addJobTemplates')}
				formId={FormId.INSTALLATION_JOB}>
				<InstallationJobForm onSubmit={onSubmit} />
			</FreesiModal>
		</>
	);
}
