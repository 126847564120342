import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';

interface Props {
	type: string;
	open: boolean;
	setOpen: (open: boolean) => void;
}

export function TemplateGroupHeader({ type, open, setOpen }: Props) {
	const { unsafeTranslation } = useTypedTranslation();

	return (
		<CollapsibleLocationDiv onClick={() => setOpen(!open)}>
			<TemplateTitle> {unsafeTranslation(`installation.jobs.types.${type}`)}</TemplateTitle>
			{open ? <ExpandLess /> : <ExpandMore />}
		</CollapsibleLocationDiv>
	);
}

const TemplateTitle = styled.span`
	font-size: 18px;
	color: #00195f;
	font-weight: 500;
`;
const CollapsibleLocationDiv = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	margin: 10px;
	cursor: pointer;
	width: fit-content;
`;
