import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight, InsightLogEntry } from '../../../../models/Insights';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { FreesiModal } from '../../FreesiModal';
import { LogEntryModalContent } from './LogEntryModalContent';

interface EditProps {
	selectedLogEntry: InsightLogEntry;
	insight: Insight;
	onClose: () => void;
	open: boolean;
}
export function EditLogEntryModal({ selectedLogEntry, insight, onClose, open }: EditProps) {
	const { t } = useTypedTranslation();
	const { updateInsightLogEntry } = useInsightActions();

	function saveLogMessage(message: string, attachments: string[]) {
		void updateInsightLogEntry({ ...selectedLogEntry, message, attachments }, insight.id);
		onClose();
	}

	return (
		<FreesiModal maxWidth="sm" title={t('eventModal.insight-log')} open={open} onClose={onClose}>
			<LogEntryModalContent
				insight={insight}
				handleSave={saveLogMessage}
				logUserName={selectedLogEntry.createdBy}
				initialMessage={selectedLogEntry.message}
				initialAttachments={selectedLogEntry.attachments}
			/>
		</FreesiModal>
	);
}
