import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useState } from 'react';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight, LogEntryType, Status } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { InsightStateButton } from '../../../buttons/InsightStateButton';
import { FormId } from '../../../form/FormId';
import { FreesiModal } from '../../FreesiModal';
import { ResolveInsightFormSchema } from './getResolveInsightFormSchema';
import { ResolveInsightForm } from './ResolveInsightForm';

interface Props {
	insight: Insight;
	includeAddTasks?: boolean;
	dataCy?: string;
}

export function ResumePendingInsight({ insight, includeAddTasks, dataCy }: Props) {
	const { t } = useTypedTranslation();
	const { resumePendingInsight } = useInsightActions();
	const { buildInsightRequest } = useBuildInsightRequest();
	const [modalOpen, setModalOpen] = useState(false);

	function handleClose() {
		setModalOpen(false);
	}

	async function handleResolvePendingInsight(data: ResolveInsightFormSchema) {
		const logEntry = {
			responsible: data.responsibleEmail,
			expectedDate: data.expectedDate,
			message: data.message,
			attachments: data.attachments,
			type: LogEntryType.PENDING_RESOLVED
		};
		const handleRequest = buildInsightRequest(Status.IN_PROGRESS, logEntry, data.tasks);
		await resumePendingInsight(insight.id, handleRequest);
	}

	return (
		<>
			<InsightStateButton
				icon={<PlayCircleOutlineIcon style={{ fontSize: '1.9rem' }} />}
				label="action.resume"
				onClick={() => setModalOpen(true)}
				dataCy={dataCy}
			/>
			<FreesiModal
				maxWidth="sm"
				open={modalOpen}
				title={t('action.resume')}
				cancelable
				onCancel={handleClose}
				submittable
				formId={FormId.INSIGHT}>
				<ResolveInsightForm onSubmit={handleResolvePendingInsight} includeAddTasks={includeAddTasks} />
			</FreesiModal>
		</>
	);
}
