import { useTriggerValueChangeOnWatch } from '../../../form/hooks/useTriggerValueChangeOnWatch';
import { InstallationJob, installationJobTemplateSchema } from '../../model/InstallationJob';
import { Path, UseFormReturn } from 'react-hook-form';
import { SupportedLocale } from '../../../../i18n/getLocaleStrings';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { createStep } from './InstallationJobForm';

interface Props {
	form: UseFormReturn<InstallationJob>;
	lang: SupportedLocale;
	watchedName: Path<InstallationJob>;
	changedName: Path<InstallationJob>;
	isStep?: boolean;
}

export function useDefaultFieldContent({ form, lang, watchedName, changedName, isStep }: Props) {
	const { i18n } = useTypedTranslation();

	// TODO fix ts-ignores. Should still work, and prob not worth the gymnastics needed
	useTriggerValueChangeOnWatch(
		installationJobTemplateSchema,
		watchedName as keyof InstallationJob,
		changedName as keyof InstallationJob,
		(selectedType, currentTitle, _) => {
			if (lang === 'en' || selectedType === 'GENERIC') return currentTitle ?? createStep();
			return {
				// @ts-ignore
				...currentTitle,
				[lang]: i18n.t(`installation.jobs.${isStep ? 'stepTypes' : 'types'}.${selectedType}`, { lng: lang })
			};
		},
		form
	);
}
