import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight, LogEntryType } from '../../../../models/Insights';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { useCurrentUser } from '../../../../stores/session/sessionHelperHooks';
import { FreesiModal } from '../../FreesiModal';
import { LogEntryModalContent } from './LogEntryModalContent';
import { sortDescBy } from '../../../../utils/helperFunctions';
import { isEmpty } from 'lodash';

interface Props {
	insight: Insight;
	onClose: () => void;
	open: boolean;
}

export function AddLogEntryModal({ insight, onClose, open }: Props) {
	const { t } = useTypedTranslation();
	const currentUser = useCurrentUser();
	const { addLogEntryAsComment } = useInsightActions();

	async function handleAddLogEntryComment(message: string, attachments: string[]) {
		if (!currentUser) return;
		const newEntry = {
			created: new Date().toISOString(),
			createdBy: currentUser.id,
			status: insight.status,
			message: message,
			taskId: findTaskIdForComment(insight),
			type: LogEntryType.COMMENT,
			attachments: attachments
		};
		await addLogEntryAsComment(insight.id, newEntry);
		onClose();
	}

	if (!currentUser) return null;
	return (
		<FreesiModal maxWidth="sm" title={t('eventModal.insight-log')} open={open} onClose={onClose}>
			<LogEntryModalContent
				insight={insight}
				logUserName={currentUser.fullName}
				handleSave={handleAddLogEntryComment}
			/>
		</FreesiModal>
	);
}

function findTaskIdForComment(insight: Insight) {
	if (isEmpty(insight.logEntries)) return undefined;
	const sortedLogs = sortDescBy(insight.logEntries, 'created');
	return sortedLogs[0]?.taskId ?? undefined;
}
