import { create } from 'zustand';
import { installationApiFactory } from '../../api/installationApi';
import { Installation } from '../../components/installation/model/Installation';
import { InstallationAcceptance } from './InstallationAcceptance';
import { useInstallationContext } from '../../components/installation/InstallationContext';
import { refreshInstallation } from './InstallationStore';
import { InstallationAcceptanceWrapper } from './InstallationAcceptanceWrapper';

interface State {
	data: Record<string, InstallationAcceptance>;
	hasChanges: boolean;
	saving: boolean;
}

interface Actions {
	update: (installation: Installation, dryRun: InstallationAcceptance) => Promise<void>;
	submit: (installation: Installation, dryRun: InstallationAcceptance) => Promise<void>;
	save: (installation: Installation) => Promise<void>;
}

interface InternalActions {
	fetchData: (installation: Installation) => Promise<void>;
}

const useZustand = create<State & Actions & InternalActions>((set, get) => ({
	data: {},
	hasChanges: false,
	saving: false,
	async fetchData(installation) {
		const id = installation.id ?? '';
		const data = await installationApiFactory().dryRunAccept(id);
		// [[kludge]] doing the install validation has a side effect where it stores the validation changes
		// to install, which also updates the timestamps. For this we need to refresh the installation so that
		// it won't complain about installation being off sync
		await refreshInstallation(installation, id);
		set({
			data: {
				...get().data,
				[id]: data
			}
		});
	},
	async update(installation, dryRun) {
		const id = installation.id ?? '';
		set({
			[id]: dryRun,
			hasChanges: true
		});
	},
	async save(installation) {
		set({ saving: true });
		const id = installation.id ?? '';
		const dryRun = get().data[id];
		await installationApiFactory().updateAccept(id, dryRun);
		await refreshInstallation(installation, id);
		set({ hasChanges: false, saving: false });
	},
	async submit(installation, dryRun) {
		const id = installation.id ?? '';
		const data = await installationApiFactory().accept(id, dryRun);
		//TODO do sthing with returned log
		await refreshInstallation(installation, id);
	}
}));

export function useInstallationDryRun() {
	const installation = useInstallationContext();
	const { fetchData, data } = useZustand();
	const id = installation.data.id ?? '';

	if (!data[id]) {
		void fetchData(installation.data);

		return {
			dryRunResult: undefined,
			loading: true
		};
	} else {
		return {
			dryRunResult: data[id],
			loading: false,
			wrapper: new InstallationAcceptanceWrapper(data[id])
		};
	}
}

export function useInstallationDryRunHasChanges() {
	return useZustand(({ hasChanges, saving }) => ({ hasChanges, saving }));
}

export function useInstallationDryRunActions() {
	return useZustand() as Actions;
}

export type InstallationDryRunActions = Actions;
