import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Step, StepContent, StepLabel, Tooltip } from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { useBrandColors } from '../../../../brands/useBrandTheme';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { LogEntryType, Status } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { formatTimestampUTC } from '../../../../utils/dateTimeFormat';
import { FreesiButton } from '../../../styled/FreesiButton';
import { Column, Row } from '../../modalUtils';
import { EntryMessageDesc, EntryMessageText, EntryTitle, EntryWrapper } from '../InsightModal.styles';
import { MappedTimelineEntry } from './mapTasksAndLog';

interface Props {
	entry: MappedTimelineEntry;
	onComplete: () => void;
	onDelete: () => void;
	onOpenAttachment: (a: string[]) => void;
	onCommentSelected: (created: string) => void;
	canProcessInsight: boolean;
	currentUser: UserModel;
	insightStatus: Status;
}

export function TimelineEntry({
	entry,
	onComplete,
	onDelete,
	onOpenAttachment,
	canProcessInsight,
	onCommentSelected,
	currentUser,
	insightStatus
}: Props) {
	const { t } = useTypedTranslation();
	const colors = useBrandColors();

	const canBeDeleted =
		!entry.completed && !entry.logs.length && entry.createdBy === currentUser?.id && canProcessInsight;
	const isDisabled = !canProcessInsight && !entry.completed;
	const canBeCompleted = insightStatus !== Status.OPEN && !entry.completed && canProcessInsight;

	return (
		<Step key={entry.title} active={true} completed={!!entry.completed} data-cy="timeline-entry">
			<Row justifyContent="space-between" alignItems="flex-start">
				<StepLabel
					data-cy="step-label"
					style={{ cursor: canBeCompleted ? 'pointer' : 'auto' }}
					onClick={canBeCompleted ? onComplete : undefined}
					StepIconComponent={() => (
						<Tooltip title={canBeCompleted ? t('eventModal.tasks.complete-task') : ''}>
							{selectIconForEntry(entry, isDisabled)}
						</Tooltip>
					)}>
					<EntryTitle data-cy="entry-title" color={isDisabled ? '#a6a6a6' : '#00195f'}>
						{entry.title}
					</EntryTitle>
				</StepLabel>
				{canBeDeleted && (
					<FreesiButton
						icon={<RemoveCircleOutlineIcon style={{ color: colors.misc.errorIcon, opacity: 0.7 }} />}
						label="action.delete"
						onClick={onDelete}
						data-cy="delete-task-button"
					/>
				)}
			</Row>
			<StepContent>
				{entry.logs.map((log, logIndex) => (
					<EntryWrapper
						editable={log.editable && log.createdBy === currentUser.id}
						key={logIndex}
						onClick={log.editable ? () => onCommentSelected(log.created) : undefined}>
						<Column gap="5px">
							{log.type === LogEntryType.TASK_COMPLETED && !log.message ? (
								<EntryMessageDesc>
									{t('eventModal.tasks.completed')} {log.createdBy} - {formatTimestampUTC(log.created)}
								</EntryMessageDesc>
							) : (
								<>
									<EntryMessageText data-cy="entry-message">{log.message}</EntryMessageText>
									<EntryMessageDesc>
										{log.createdBy} - {formatTimestampUTC(log.created)}
									</EntryMessageDesc>
								</>
							)}
						</Column>
						{log.attachments && !!log.attachments.length && (
							<FreesiButton
								icon={<AttachFileIcon style={{ color: '#61668B' }} />}
								label="eventModal.view-attachments"
								onClick={e => {
									e.stopPropagation();
									onOpenAttachment(log.attachments ?? []);
								}}
								data-cy="open-attachment-button"
							/>
						)}
					</EntryWrapper>
				))}
			</StepContent>
		</Step>
	);
}

function selectIconForEntry(entry: MappedTimelineEntry, isDisabled: boolean) {
	switch (entry.status) {
		case Status.PENDING:
			return <AccessTimeIcon style={{ color: '#F7CF4F' }} data-cy="pending-icon" />;
		case Status.REPAIRED:
			return <CheckCircleOutlineOutlinedIcon style={{ color: '#4F9572' }} data-cy="repaired-icon" />;
		case Status.DECLINED:
			return <BlockOutlinedIcon style={{ color: '#D63737' }} data-cy="declined-icon" />;
		default:
			return entry.completed ? (
				<GridCheckCircleIcon style={{ color: '#5bf7bf' }} data-cy="completed-icon" />
			) : (
				<RadioButtonUncheckedIcon
					style={{
						cursor: isDisabled ? 'auto' : 'pointer',
						color: isDisabled ? '#a6a6a6' : '#00195f'
					}}
					data-cy="default-icon"
				/>
			);
	}
}
