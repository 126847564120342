import SupportIcon from '@mui/icons-material/Support';
import { useState } from 'react';
import { useTypedTranslation } from '../../../../../hooks/useTypedTranslation';
import { Insight, InsightHoldReason, LogEntryType, Status } from '../../../../../models/Insights';
import { useBuildInsightRequest, useInsightActions } from '../../../../../stores/insights/InsightStore';
import { InsightStateButton } from '../../../../buttons/InsightStateButton';
import { LabelValueRow } from '../../LabelValueRow';
import { ProcessInsightModal } from '../ProcessInsightModal';

interface Props {
	insight: Insight;
	dataCy?: string;
}

export function GetSupport({ insight, dataCy }: Props) {
	const { t } = useTypedTranslation();
	const { pauseInsight } = useInsightActions();
	const { buildInsightLogEntry } = useBuildInsightRequest();

	const [modalOpen, setModalOpen] = useState(false);

	async function handleHoldInsight(message: string, attachments: string[]) {
		const logEntry = {
			responsible: insight.supervisor?.email,
			message,
			attachments,
			reason: InsightHoldReason.SUPERVISOR_SUPPORT,
			type: LogEntryType.PENDING_SUPPORT
		};
		const entry = buildInsightLogEntry(logEntry, Status.PENDING);
		await pauseInsight(insight.id, entry);
	}

	return (
		<>
			<InsightStateButton
				icon={<SupportIcon style={{ fontSize: '1.9rem' }} />}
				label="eventModal.request-support.help"
				onClick={() => setModalOpen(true)}
				color="#00195f"
				dataCy={dataCy}
			/>
			<ProcessInsightModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				modalLabel="eventModal.request-support.modal-title"
				onSubmit={handleHoldInsight}
				extraInfo={t('eventModal.request-support.info')}
				messageLabel="general.form.details"
				messageFieldTooltip={t('eventModal.request-support.message-box-info')}
				extraChildren={<LabelValueRow label={t('eventModal.supervisor')} value={insight.supervisor?.fullName} />}
			/>
		</>
	);
}
