import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { InstallationJob } from '../../model/InstallationJob';
import { SupportedLocale } from '../../../../i18n/getLocaleStrings';
import { useDefaultFieldContent } from './useDefaultFieldContent';

interface Props {
	form: UseFormReturn<InstallationJob>;
	lang: SupportedLocale;
}

export function usePrepopulateJobFields({ form, lang }: Props) {
	const selectedLanguages = form.watch('selectedLanguages');
	useEffect(() => {
		const data = form.getValues();
		selectedLanguages.forEach(locale => {
			if (locale === 'en') return;
			if (data.title[locale]) return; // prevent resetting these after a language is added
			form.setValue(`title.${locale}`, data.title.en);
			form.setValue(`instructions.${locale}`, data.instructions.en);

			for (const stepIndex in data.steps) {
				form.setValue(`steps.${parseInt(stepIndex)}.title.${locale}`, data.steps[stepIndex].title.en);
				form.setValue(`steps.${parseInt(stepIndex)}.instructions.${locale}`, data.steps[stepIndex].instructions.en);
			}
			for (const bomIndex in data.billOfMaterials) {
				form.setValue(
					`billOfMaterials.${parseInt(bomIndex)}.title.${locale}`,
					data.billOfMaterials?.[parseInt(bomIndex)].title.en
				);
			}
		});
	}, [selectedLanguages]);

	useDefaultFieldContent({ form, lang, watchedName: 'type', changedName: 'title' });
}
