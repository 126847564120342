import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SortableItem } from './SortableItem';

interface Props<T> {
	items: T[];
	renderItem: (item: T, index: number) => React.ReactNode;
	onChange: (newOrder: T[]) => void;
	keyGetter: (item: T, index: number) => string;
	canSort: boolean;
	setItems: (items: T[]) => void;
}

export function SortableList<T>({ items, renderItem, onChange, keyGetter, canSort, setItems }: Props<T>) {
	const moveItem = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const newItems = [...items];
			const [removed] = newItems.splice(dragIndex, 1);
			newItems.splice(hoverIndex, 0, removed);
			setItems(newItems);
			onChange(newItems);
		},
		[items, onChange]
	);

	return (
		<>
			{!canSort ? (
				<>
					{items.map((item, index) => (
						<div key={keyGetter(item, index)}>{renderItem(item, index)}</div>
					))}
				</>
			) : (
				<DndProvider backend={HTML5Backend}>
					{items.map((item, index) => (
						<SortableItem
							key={index}
							item={item}
							index={index}
							moveItem={moveItem}
							renderItem={item => renderItem(item, index)}
							keyGetter={keyGetter}
						/>
					))}
				</DndProvider>
			)}
		</>
	);
}
