import { AccessTime } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { useState } from 'react';
import useLocale from '../../../../hooks/useLocale';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight } from '../../../../models/Insights';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { Row } from '../../modalUtils';
import { iconStyle, InsightText } from '../InsightModal.styles';
import { useCheckInsightPermissions } from '../process-insight/useCheckInsightPermissions';

interface Props {
	insight: Insight;
	disabled: boolean;
}

export function InsightDueDateRow({ insight, disabled }: Props) {
	const { t } = useTypedTranslation();
	const { locale, dateFormat, dateMask } = useLocale();
	const { hasSupervisorPermission } = useCheckInsightPermissions(insight);

	const [open, setOpen] = useState(false);
	const [date, setDate] = useState<Date | undefined>(insight.latest?.expectedDate);

	const { updateInsightDetails } = useInsightActions();

	function handleChangeDate(newDate: Date | null | undefined, i?: string | undefined) {
		const expectedDate = newDate ?? undefined;
		setDate(expectedDate);
		updateInsightDetails(insight.id, { expectedDate });
	}

	return (
		<div>
			<InsightText>{t('eventModal.expected-date')}</InsightText>
			<Row alignItems="center">
				<AccessTime sx={iconStyle} />
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
					<DatePicker
						open={open}
						onOpen={() => setOpen(true)}
						onClose={() => setOpen(false)}
						inputFormat={dateFormat}
						mask={dateMask}
						renderInput={props => (
							<TextField
								{...props}
								variant="standard"
								fullWidth
								onClick={e => setOpen(true)}
								InputProps={{
									...props.InputProps,
									disableUnderline: open ? false : true,
									sx: {
										backgroundColor: disabled ? 'inherit' : 'white',
										border: '1px solid transparent',
										transition: 'border-color 0.3s, background-color 0.3s',
										'&:hover': {
											backgroundColor: !disabled ? '#f5f5f9' : 'inherit',
											borderColor: !disabled ? '#b3b3b3' : 'transparent'
										}
									}
								}}
							/>
						)}
						value={date}
						onChange={handleChangeDate}
						InputAdornmentProps={{ style: { display: 'none' } }}
						disabled={!hasSupervisorPermission}
					/>
				</LocalizationProvider>
			</Row>
		</div>
	);
}
