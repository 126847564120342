import { useEffect } from 'react';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { InsightsApi, insightsApiFactory } from '../../api/insightsApi';
import {
	Approval,
	CapexRequest,
	Evidence,
	Insight,
	InsightHandleRequest,
	InsightLogEntry,
	LogEntryType,
	ManualInsightRequest,
	Status,
	Task,
	UpdateInsightDetailsDTO
} from '../../models/Insights';
import { isDevMode } from '../../test/utils/isDevMode';
import { useCurrentUser } from '../session/sessionHelperHooks';
import { getSnackbar } from '../snackbar/SnackbarHelper';
import { DataStoreAccessor } from '../utils/genericdatastore/DataStoreAccessor';
import { DataStoreState } from '../utils/genericdatastore/DataStoreState';
import { StoredData } from '../utils/genericdatastore/StoredData';
import { loadingState } from '../utils/loadingState';
import { invalidateInsightsLists } from './InsightsListStore';

type State = DataStoreState<string, never, never, Insight>;

interface Actions {
	fetchInsight: (id: string, invalidate?: boolean) => Promise<void>;
	addManualInsight: (manualInsight: ManualInsightRequest) => Promise<string>;
	updateInsightLogEntry: (value: InsightLogEntry, insightId: string) => Promise<void>;
	addLogEntryAsComment: (insightId: string, logEntry: InsightLogEntry) => Promise<void>;
	addTasksToInsight: (insightId: string, tasks: Task[]) => Promise<void>;
	completeSingleTask: (insightId: string, taskId: string, logEntry: InsightLogEntry) => Promise<void>;
	removeSingleTask: (insightId: string, taskId: string) => Promise<void>;
	addInsightEvidence: (insightId: string, evidence: Evidence) => Promise<void>;
	updateInsightDetails: (insightId: string, updatedDetails: UpdateInsightDetailsDTO) => Promise<void>;
	startInsight: (insightId: string) => Promise<void>;
	completeInsight: (insightId: string, request: InsightHandleRequest) => Promise<void>;
	pauseInsight: (insightId: string, logEntry: InsightLogEntry) => Promise<void>;
	resumePendingInsight: (insightId: string, request: InsightHandleRequest) => Promise<void>;
	recordApprovalForInsightCompletion: (insightId: string, approval: Approval) => Promise<void>;
	sendCAPEX: (insightId: string, CAPEX: CapexRequest) => Promise<void>;
}

const useZustand = create<State & Actions>((set, get) => {
	const ds = new DataStoreAccessor(get, set, insightsApiFactory, (i: Insight) => i.id);
	const insightFetcher = (id: string) => (api: InsightsApi) => api.getSingleInsight(id);
	return {
		items: new Map(),

		async fetchInsight(id, invalidate) {
			try {
				await ds.doFetchItem(id, insightFetcher(id), invalidate);
			} catch (error) {
				getSnackbar().showDefaultError(error);
			}
		},
		async updateInsightLogEntry(value, insightId) {
			try {
				getSnackbar().showInfo('snackbarMessages.insight.process.inProgress');
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.updateInsightLogEntry(insightId, value);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		},
		async addManualInsight(insight) {
			try {
				getSnackbar().showInfo('snackbarMessages.manualInsight.create.inProgress');
				const insightId = await ds.getApi().createManualInsight(insight);
				getSnackbar().showSuccess('snackbarMessages.manualInsight.create.success');
				invalidateInsightsLists();
				return insightId;
			} catch (e) {
				getSnackbar().showDefaultError(e);
				return '';
			}
		},
		async addLogEntryAsComment(insightId, logEntry) {
			try {
				getSnackbar().showInfo('snackbarMessages.insight.process.inProgress');
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.addLogEntryAsComment(insightId, logEntry);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error);
			}
		},
		async addTasksToInsight(insightId, tasks) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.addTasksToInsight(insightId, tasks);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error);
			}
		},
		async completeSingleTask(insightId, taskId, logEntry) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.completeSingleTask(insightId, taskId, logEntry);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error);
			}
		},
		async removeSingleTask(insightId, taskId) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.removeSingleTask(insightId, taskId);
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error);
			}
		},
		async addInsightEvidence(insightId, evidence) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.addInsightEvidence(insightId, evidence);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error);
			}
		},
		async updateInsightDetails(insightId, updatedDetails) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.updateInsightDetails(insightId, updatedDetails);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
				invalidateInsightsLists();
			} catch (error: any) {
				getSnackbar().showDefaultError(error);
			}
		},
		async startInsight(insightId) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.startInsight(insightId);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
				invalidateInsightsLists();
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		},
		async completeInsight(insightId, request) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.completeInsight(insightId, request);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success-final');
					},
					insightFetcher(insightId)
				);
				invalidateInsightsLists();
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		},
		async pauseInsight(insightId, logEntry) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.pauseInsight(insightId, logEntry);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
				invalidateInsightsLists();
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		},
		async resumePendingInsight(insightId, request) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.resumePendingInsight(insightId, request);
						getSnackbar().showSuccess('snackbarMessages.insight.process.success');
					},
					insightFetcher(insightId)
				);
				invalidateInsightsLists();
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		},
		async recordApprovalForInsightCompletion(insightId, approval) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.recordApprovalForInsightCompletion(insightId, approval);
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		},
		async sendCAPEX(insightId, CAPEX) {
			try {
				await ds.doMutatingActionWithoutResponse(
					insightId,
					async api => {
						await api.sendCAPEX(insightId, CAPEX);
					},
					insightFetcher(insightId)
				);
			} catch (error: any) {
				getSnackbar().showDefaultError(error, { 404: 'snackbarMessages.insight.process.notFound' });
			}
		}
	};
});

export function useInsightActions() {
	return useZustand() as Actions;
}

export function useInsight(insightId?: string): StoredData<Insight> {
	const { items, fetchInsight } = useZustand();

	useEffect(() => {
		if (insightId) void fetchInsight(insightId);
	}, [fetchInsight, insightId]);

	return items?.get(insightId ?? '') ?? loadingState;
}

export function useBuildInsightRequest() {
	const currentUser = useCurrentUser();

	function buildInsightLogEntry(logEntry: Partial<InsightLogEntry>, status: Status): InsightLogEntry {
		const createdDate = new Date().toISOString();

		return {
			created: createdDate,
			createdBy: currentUser?.id,
			type: logEntry.type ?? LogEntryType.GENERAL,
			status,
			message: logEntry.message ?? '',
			...logEntry
		};
	}

	function buildInsightRequest(
		status: Status,
		logEntry: Partial<InsightLogEntry>,
		tasks?: string[]
	): InsightHandleRequest {
		const newTasks = tasks
			? tasks
					.filter(description => description !== '')
					.map(description => ({
						description,
						creatorId: currentUser?.id
					}))
			: [];

		return {
			insightLogEntry: buildInsightLogEntry(logEntry, status),
			status,
			...(newTasks.length > 0 && { newTasks })
		};
	}

	return { buildInsightRequest, buildInsightLogEntry };
}

if (isDevMode()) mountStoreDevtool('InsightsStore', useZustand);
