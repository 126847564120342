import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { useInsightActions } from '../../../stores/insights/InsightStore';
import { useActivePremises } from '../../../stores/location/ActivePremisesStore';
import { useLocationHeaders } from '../../../stores/location/LocationHeaderStore';
import { useCurrentUser, useCurrentUserId } from '../../../stores/session/sessionHelperHooks';
import { findById } from '../../../utils/findById';
import DelaySpinner from '../../delay-spinner/DelaySpinner';
import { FormId } from '../../form/FormId';
import { FreesiModal } from '../FreesiModal';
import { InsightForm } from './InsightForm';
import { DEFAULT_END, DEFAULT_MEASUREMENT_TYPE, DEFAULT_START } from './evidence/insightEvidenceDefaultConfig';
import { InsightFormSchema } from './getInsightFormSchema';

interface Props {
	onClose: () => void;
	open: boolean;
	locationId?: string;
	premiseId?: string;
}

export function AddManualInsightModal({ onClose, locationId, premiseId, open }: Props) {
	const { t } = useTypedTranslation();
	const { data: locations } = useLocationHeaders();
	const location = findById(locations, locationId);
	const { data: premises } = useActivePremises(locationId || undefined);
	const premise = findById(premises, premiseId);
	const currentUser = useCurrentUser();
	const { addManualInsight } = useInsightActions();

	async function onSubmit({ insightMoment, ...others }: InsightFormSchema) {
		await addManualInsight({ ...others, madeBy: currentUser?.id, insightMoment: insightMoment! });
		onClose();
	}

	if (!currentUser) return <DelaySpinner />;

	return (
		<FreesiModal
			open={open}
			onCancel={onClose}
			title={t('modal.title.new-insight')}
			formId={FormId.INSIGHT}
			cancelable
			submittable
			maxWidth="md">
			<InsightForm
				onSubmit={onSubmit}
				location={location}
				premise={premise}
				requireDate={true}
				defaultStart={DEFAULT_START}
				defaultEnd={DEFAULT_END}
				defaultMeasurementType={DEFAULT_MEASUREMENT_TYPE}
				currentUser={currentUser}
			/>
		</FreesiModal>
	);
}
