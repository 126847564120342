import { Insight, Status } from '../../../../models/Insights';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { ProcessInsightModal } from './ProcessInsightModal';

interface Props {
	insight: Insight;
	dataCy?: string;
	modalOpen: boolean;
	onClose: () => void;
}

export function DeclineInsightWithoutApproval({ insight, dataCy, modalOpen, onClose }: Props) {
	const { completeInsight } = useInsightActions();
	const { buildInsightRequest } = useBuildInsightRequest();

	async function handleDeclineInsight(message: string, attachments: string[]) {
		const logEntry = { message, attachments };
		const handleRequest = buildInsightRequest(Status.DECLINED, logEntry);
		await completeInsight(insight.id, handleRequest);
	}

	return (
		<ProcessInsightModal
			open={modalOpen}
			onClose={onClose}
			modalLabel="action.decline"
			messageLabel="general.reason"
			onSubmit={handleDeclineInsight}
		/>
	);
}
