import { useTypedTranslation } from '../../../../../hooks/useTypedTranslation';
import { Insight, InsightHoldReason, LogEntryType, Status } from '../../../../../models/Insights';
import { useBuildInsightRequest, useInsightActions } from '../../../../../stores/insights/InsightStore';
import { LabelValueRow } from '../../LabelValueRow';
import { ProcessInsightModal } from '../ProcessInsightModal';

interface Props {
	insight: Insight;
	modalOpen: boolean;
	onClose: () => void;
	reason: InsightHoldReason;
}

export function HoldInsightForApproval({ insight, modalOpen, onClose, reason }: Props) {
	const { t } = useTypedTranslation();
	const { pauseInsight } = useInsightActions();
	const { buildInsightLogEntry } = useBuildInsightRequest();

	async function handleHoldInsight(message: string, attachments: string[]) {
		const logEntry = {
			message,
			attachments,
			responsible: insight.supervisor?.email,
			reason,
			type: LogEntryType.PENDING_APPROVAL
		};
		const handleRequest = buildInsightLogEntry(logEntry, Status.PENDING);
		await pauseInsight(insight.id, handleRequest);
	}

	const modalLabel =
		reason === InsightHoldReason.SUPERVISOR_DECLINE_APPROVAL
			? 'organizationModal.features.INSIGHT_DECLINE_ACTION_REQUIRES_SUPERVISOR_APPROVAL'
			: 'organizationModal.features.INSIGHT_COMPLETE_ACTION_REQUIRES_SUPERVISOR_APPROVAL';

	return (
		<ProcessInsightModal
			open={modalOpen}
			onClose={onClose}
			modalLabel={modalLabel}
			tooltipInfo="eventModal.approval.request-supervisor-approval"
			onSubmit={handleHoldInsight}
			extraChildren={<LabelValueRow label={t('eventModal.supervisor')} value={insight.supervisor?.fullName} />}
		/>
	);
}
