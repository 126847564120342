import { FiberManualRecord } from '@mui/icons-material';
import styled from 'styled-components';
import { Status } from '../../../models/Insights';

export const InsightDialogBody = styled.div`
	padding: 0px 10px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const InsightTitle = styled('span')`
	font-size: 18px;
	color: #00195f;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: fit-content;
	font-weight: 500;
`;

export const EntryTitle = styled('span')<{ color: string }>`
	font-size: 16px;
	color: ${props => props.color};
	font-weight: bolder;
`;
export const EntryWrapper = styled.div<{ editable: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 10px;
	background-color: #f5f5f8;
	border-radius: 8px;
	margin-bottom: 5px;
	cursor: ${props => (props.editable ? 'pointer' : 'auto')};
`;

export const AddStepLabel = styled.span`
	cursor: pointer;
	font-size: 14px;
	color: #61668b;
	fomt-weight: 500;
`;

export const EntryMessageText = styled('span')`
	font-size: 16px;
	color: #00195f;
`;

export const EntryMessageDesc = styled('span')`
	font-size: 12px;
	color: #61668b;
`;

export const InsightText = styled('span')`
	font-size: 14px;
	letter-spacing: 0.6px;
	color: #171a2b;
`;

export const iconStyle = { color: '#61668B', fontSize: '1rem', marginRight: '0.4rem' };

interface StyleProps {
	currentstatus: Status;
}

export const InsightStatusIcon = styled(FiberManualRecord)<StyleProps>`
	color: ${props =>
		props.currentstatus === Status.OPEN
			? '#00195F'
			: props.currentstatus === Status.PENDING
			? '#F7CF4F'
			: props.currentstatus === Status.DECLINED
			? '#D63737'
			: props.currentstatus === Status.IN_PROGRESS
			? '#fd7e14'
			: '#5bf7bf'};
	font-size: 0.9rem;
	margin-right: 0.4rem;
`;

export const StateButtonText = styled('span')<{ disabled?: boolean }>`
	color: ${props => (props.disabled ? '#a6a6a6' : '#00195f')};
	text-align: center;
`;

export const ButtonContainer = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`;

export const ColoredText = styled.span`
	color: #00195f;
`;

export const ColoredBoldText = styled.span`
	font-weight: 500;
	color: #00195f;
`;

export const MeasurementText = styled.span`
	text-transform: uppercase;
	color: #00195f;
	align-self: center;
	margin-top: 10px;
`;

export const CapexWrapper = styled.div`
	display: flex;
	flex-direction: row;
	padding: 20px;
	flex-wrap: wrap;
	gap: 10%;
	background-color: #f5f5f8;
	border-radius: 8px;
`;
