import { PersonOutline } from '@mui/icons-material';
import { debounce, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight } from '../../../../models/Insights';
import { UserHeader } from '../../../../models/User';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { UserInstantSearch } from '../../../user-search/UserInstantSearch';
import { Row } from '../../modalUtils';
import { iconStyle, InsightText } from '../InsightModal.styles';

interface Props {
	insight: Insight;
	disabled: boolean;
}

export function InsightResponsibleRow({ insight, disabled }: Props) {
	const { t } = useTypedTranslation();
	let responsible = insight.latest?.responsibleEmail || '-';
	const { updateInsightDetails } = useInsightActions();

	const debouncedUpdateResponsibleUser = useCallback(
		debounce((responsibleEmail: string) => {
			updateInsightDetails(insight.id, { responsibleEmail });
		}, 1000),
		[insight.id]
	);

	function handleChangeResponsibleUser(value: UserHeader | string) {
		if (isEmpty(value)) return;
		if (typeof value === 'string') {
			if (value !== responsible) {
				debouncedUpdateResponsibleUser(value);
			}
		} else {
			if (value.fullName !== responsible && value.email !== responsible) {
				debouncedUpdateResponsibleUser(value.email);
			}
		}
	}

	return (
		<div style={{ width: 'fit-content' }}>
			<InsightText>{t('eventModal.responsible')}</InsightText>
			<Row alignItems="center" style={{ minWidth: '200px' }}>
				<PersonOutline sx={iconStyle} />
				<UserInstantSearch
					onChange={handleChangeResponsibleUser}
					getOptionLabel={o => o.email}
					defaultValue={responsible}
					disabled={disabled}
				/>
			</Row>
		</div>
	);
}
