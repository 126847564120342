import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useState } from 'react';
import { Insight, InsightHoldReason, LogEntryType } from '../../../../models/Insights';
import { InsightStateButton } from '../../../buttons/InsightStateButton';
import { RepairInsightWithoutApproval } from './RepairInsightWithoutApproval';
import { HoldInsightForApproval } from './approval-flow/HoldInsightForApproval';
import { useCheckInsightPermissions } from './useCheckInsightPermissions';

interface Props {
	insight: Insight;
	dataCy?: string;
}

export function RepairInsight({ insight, dataCy }: Props) {
	const { closeInsightDoesNotNeedOwnerApproval } = useCheckInsightPermissions(insight);
	const [modalOpen, setModalOpen] = useState(false);

	function handleModalClose() {
		setModalOpen(false);
	}

	return (
		<>
			<InsightStateButton
				icon={<CheckCircleOutlineOutlinedIcon style={{ fontSize: '1.9rem' }} />}
				label="action.complete"
				onClick={() => setModalOpen(true)}
				color="#4F9572"
				dataCy={dataCy}
			/>
			{closeInsightDoesNotNeedOwnerApproval ? (
				<RepairInsightWithoutApproval insight={insight} modalOpen={modalOpen} onClose={handleModalClose} />
			) : (
				<HoldInsightForApproval
					insight={insight}
					modalOpen={modalOpen}
					onClose={handleModalClose}
					reason={InsightHoldReason.SUPERVISOR_REPAIR_APPROVAL}
				/>
			)}
		</>
	);
}
