import { Editor } from '@toast-ui/react-editor';
import { useRef, useState } from 'react';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight } from '../../../../models/Insights';
import { useSnackbar } from '../../../../stores/snackbar/SnackbarHelper';
import { extractTextFromMarkdown } from '../../../../utils/markdownUtils';
import { InsightStatusColumn } from '../../../../views/insight-list/InsightStatusColumn';
import { FreesiButton } from '../../../styled/FreesiButton';
import { Column, Row } from '../../modalUtils';
import { ColoredBoldText } from '../InsightModal.styles';
import { LabelValueRow } from '../LabelValueRow';
import { MessageField } from '../process-insight/MessageField';

interface Props {
	insight: Insight;
	logUserName?: string;
	handleSave: (m: string, a: string[]) => void;
	initialMessage?: string;
	initialAttachments?: string[];
}

export function LogEntryModalContent({
	insight,
	logUserName,
	handleSave,
	initialMessage,
	initialAttachments = []
}: Props) {
	const { t } = useTypedTranslation();
	const editorRef = useRef<Editor>(null);
	const snackbar = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [attachments, setAttachments] = useState<string[]>(initialAttachments);

	function handleSubmit() {
		const editorInstance = editorRef.current?.getInstance();

		if (editorInstance) {
			setLoading(true);
			const markdownContent = editorInstance.getMarkdown();
			const textMessage = extractTextFromMarkdown(markdownContent);
			handleSave(textMessage, attachments);
			setAttachments([]);
			editorInstance.setMarkdown('');
			setLoading(false);
		} else snackbar.showError('error.generic');
	}

	return (
		<Column gap="10px">
			<LabelValueRow label={t('general.user')} value={logUserName} />
			<Row gap="5px">
				<ColoredBoldText>{t('general.status')}: </ColoredBoldText>
				<InsightStatusColumn status={insight.status} />
			</Row>
			<MessageField
				editorRef={editorRef}
				attachments={attachments}
				setAttachments={setAttachments}
				initialMessage={initialMessage}
			/>
			<FreesiButton loading={loading} label="general.save" onClick={handleSubmit}></FreesiButton>
		</Column>
	);
}
