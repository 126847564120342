import CloseIcon from '@mui/icons-material/Close';
import { Drawer } from '@mui/material';
import { ReactElement, ReactNode, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { TranslationKeys } from '../../locales/TranslationKeys';
import { FreesiButton } from '../styled/FreesiButton';
import { Column, Row } from '../modal/modalUtils';

interface Props {
	content: ReactNode;
	icon: ReactElement;
	buttonLabel: TranslationKeys;
	buttonStyle?: CSSProperties;
}

export function DrawerMenu({ content, icon, buttonLabel, buttonStyle }: Props) {
	const { t } = useTypedTranslation();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const drawerRef = useRef<HTMLDivElement>(null);

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	return (
		<>
			<FreesiButton style={buttonStyle} icon={icon} label={t(buttonLabel)} onClick={handleDrawerToggle} />
			<Drawer
				ref={drawerRef}
				anchor="right"
				open={drawerOpen}
				onClose={handleDrawerToggle}
				sx={{
					width: '40%',
					'& .MuiDrawer-paper': {
						display: 'flex',
						flexDirection: 'column',
						flex: 1,
						backgroundColor: '#f6f7f8',
						padding: '20px'
					}
				}}>
				<Column gap="10px">
					<Row justifyContent="flex-start">
						<FreesiButton
							icon={<CloseIcon style={{ fontSize: '30px', color: '#00195f' }} />}
							label={t('general.close')}
							onClick={() => setDrawerOpen(false)}
						/>
					</Row>
					{content}
				</Column>
			</Drawer>
		</>
	);
}
