import React from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

interface Props<T> {
	item: T;
	index: number;
	moveItem: (dragIndex: number, hoverIndex: number) => void;
	renderItem: (item: T) => React.ReactNode;
	keyGetter: (item: T, index: number) => string;
}

interface DragItem {
	index: number;
	type: string;
}

export function SortableItem<T>({ item, index, moveItem, renderItem, keyGetter }: Props<T>) {
	const ref = React.useRef<HTMLDivElement>(null);

	const [, drop] = useDrop({
		accept: 'item',
		hover(dragItem: unknown, monitor: DropTargetMonitor) {
			const item = dragItem as DragItem;
			if (!ref.current) return;
			const dragIndex = item.index;
			const hoverIndex = index;
			if (dragIndex === hoverIndex) return;

			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

			moveItem(dragIndex, hoverIndex);
			item.index = hoverIndex;
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'item',
		item: () => ({ id: item, index }),
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging()
		})
	});

	drag(drop(ref));

	return (
		<div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} key={keyGetter(item, index)}>
			{renderItem(item)}
		</div>
	);
}
