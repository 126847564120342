import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight } from '../../../../models/Insights';
import { useInsightActions } from '../../../../stores/insights/InsightStore';
import { useSnackbar } from '../../../../stores/snackbar/SnackbarHelper';
import { ConfirmModal } from '../../ConfirmModal';
import { MappedTimelineEntry } from '../timeline/mapTasksAndLog';

interface Props {
	insight: Insight;
	task: MappedTimelineEntry;
	modalOpen: boolean;
	onClose: () => void;
}

export function DeleteTaskModal({ insight, task, modalOpen, onClose }: Props) {
	const { t } = useTypedTranslation();
	const { removeSingleTask } = useInsightActions();
	const snackbar = useSnackbar();

	async function removeTask() {
		if (!task.id) {
			snackbar.showError('error.no-details');
			return;
		}
		await removeSingleTask(insight.id, task.id);
		onClose();
	}

	return (
		<ConfirmModal
			title={t('eventModal.tasks.delete-task') + '?'}
			body={t('eventModal.tasks.task') + ': ' + task.title}
			onConfirm={removeTask}
			onClose={onClose}
			open={modalOpen}
		/>
	);
}
