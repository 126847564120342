import { useTypedTranslation } from '../../../../../hooks/useTypedTranslation';
import { createApprovedApproval, Insight, Status } from '../../../../../models/Insights';
import { UserModel } from '../../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../../stores/insights/InsightStore';
import { ColoredText } from '../../InsightModal.styles';
import { ProcessInsightModal } from '../ProcessInsightModal';

interface Props {
	insight: Insight;
	currentUser: UserModel;
	dataCy?: string;
	modalOpen: boolean;
	onClose: () => void;
}

export function ApproveInsightRepairAction({ insight, currentUser, modalOpen, onClose }: Props) {
	const { t } = useTypedTranslation();
	const { completeInsight, recordApprovalForInsightCompletion } = useInsightActions();
	const { buildInsightRequest } = useBuildInsightRequest();

	async function handleRepairInsight(message: string, attachments: string[]) {
		const logEntry = { message, attachments };
		const approval = createApprovedApproval(currentUser.email, new Date(), message);
		const handleRequest = buildInsightRequest(Status.REPAIRED, logEntry);
		await completeInsight(insight.id, handleRequest);
		await recordApprovalForInsightCompletion(insight.id, approval);
	}

	return (
		<ProcessInsightModal
			open={modalOpen}
			onClose={onClose}
			modalLabel="action.approve"
			onSubmit={handleRepairInsight}
			extraChildren={<ColoredText>{t('eventModal.approval.approve-repair-action')}</ColoredText>}
		/>
	);
}
