import { useEffect, useState } from 'react';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { InsightHoldReason, InsightSummaryModel, Status } from '../../../models/Insights';
import { InsightDialogBody } from './InsightModal.styles';
import { InsightLog } from './log-entry/InsightLog';

import AddCommentIcon from '@mui/icons-material/AddComment';
import { useInsight } from '../../../stores/insights/InsightStore';
import { useCurrentUser } from '../../../stores/session/sessionHelperHooks';
import DelaySpinner from '../../delay-spinner/DelaySpinner';
import { FreesiButton } from '../../styled/FreesiButton';
import { GenericTab } from '../../tabs/GenericTabs';
import { SimpleTabs } from '../../tabs/SimpleTabs';
import { FreesiBaseModal } from '../FreesiBaseModal';
import { Column } from '../modalUtils';
import { InsightDescription } from './info/InsightDescription';
import { InsightInfoRow } from './info/InsightInfoRow';
import { InsightStateButtons } from './InsightStateButtons';
import { LocationAndPremiseNameRow } from './info/LocationAndPremiseNameRow';
import { CapexContent } from './capex/CapexContent';
import { InsightEvidence } from './evidence/InsightEvidence';
import { AddLogEntryModal } from './log-entry/AddLogEntryModal';
import { InsightTimeline } from './timeline/InsightTimeline';

type Props = {
	data: InsightSummaryModel | undefined;
	onClose: () => void;
	open: boolean;
	disableControl?: boolean;
};

export function InsightModal({ data, onClose, open, disableControl }: Props) {
	const { t } = useTypedTranslation();
	const currentUser = useCurrentUser();
	const { data: insight } = useInsight(data?.id);

	const [activeTab, setActiveTab] = useState<string>('timeline');
	const [modalOpen, setModalOpen] = useState(false);

	const tabs: GenericTab[] = [
		{
			id: 'timeline',
			translation: 'eventModal.timeline'
		}
	];
	if (insight?.evidence) {
		tabs.push({
			id: 'measurements',
			translation: 'control-room.measurements'
		});
	}
	if (insight?.capexRequest) {
		tabs.push({
			id: 'capex',
			translation: 'eventModal.CAPEX.capex'
		});
	}
	tabs.push({
		id: 'logs',
		translation: 'eventModal.logs'
	});

	useEffect(() => {
		if (insight?.status === Status.PENDING && insight?.latest?.reason === InsightHoldReason.CAPEX) {
			setActiveTab('capex');
		}
	}, [insight?.status]);

	if (!insight || !currentUser) return <DelaySpinner />;
	var evidence = insight.evidence;
	const isInsightCompleted = insight.status === Status.REPAIRED || insight.status === Status.DECLINED;
	const canProcessInsight = !isInsightCompleted && !disableControl;

	function handleAddLogEntryClose() {
		setModalOpen(false);
		setActiveTab('timeline');
	}

	return (
		<FreesiBaseModal
			open={open}
			maxWidth="md"
			title={t('modal.title.insight')}
			onClose={onClose}
			footerStyle={{ padding: '2%' }}
			customizedFooter={
				<InsightStateButtons insight={insight} canProcessInsight={canProcessInsight} currentUser={currentUser} />
			}>
			<InsightDialogBody>
				<div>
					<LocationAndPremiseNameRow {...insight} />
					<InsightInfoRow insight={insight} />
				</div>
				<InsightDescription insight={insight} />
				<Column gap="15px">
					<SimpleTabs
						tabs={tabs}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						sideMenu={
							canProcessInsight ? (
								<FreesiButton
									icon={<AddCommentIcon style={{ color: '#00195f', fontSize: '2rem' }} />}
									label="eventModal.add-log"
									onClick={() => setModalOpen(true)}
								/>
							) : undefined
						}
					/>
					{tabs.map(tab => (
						<div key={tab.id} style={{ display: activeTab === tab.id ? 'block' : 'none' }}>
							{tab.id === 'measurements' && evidence && (
								<InsightEvidence
									locationId={insight.locationId}
									premiseId={insight.premiseId!}
									hideTypeSelection
									defaultStart={evidence.startTime ? new Date(evidence.startTime) : undefined}
									defaultEnd={evidence.endTime ? new Date(evidence.endTime) : undefined}
									defaultMeasurementType={evidence.measurementType}
								/>
							)}
							{tab.id === 'timeline' && <InsightTimeline insight={insight} canProcessInsight={canProcessInsight} />}
							{tab.id === 'logs' && (
								<InsightLog data={insight.logEntries} insight={insight} canProcessInsight={canProcessInsight} />
							)}
							{tab.id === 'capex' && insight.capexRequest && <CapexContent capex={insight.capexRequest} />}
						</div>
					))}
				</Column>
				{canProcessInsight && <AddLogEntryModal insight={insight} onClose={handleAddLogEntryClose} open={modalOpen} />}
			</InsightDialogBody>
		</FreesiBaseModal>
	);
}
