import { SimpleSelect } from '../../../simple-select/SimpleSelect';
import _ from 'lodash';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { useFormContext } from 'react-hook-form';
import { SupportedLocale } from '../../../../i18n/getLocaleStrings';
import { ControlledTextField } from '../../../form/controls/ControlledTextField';

interface Props {
	index: number;
	lang: SupportedLocale;
}

export function BomEntryEditor({ index, lang }: Props) {
	const { t } = useTypedTranslation();
	const { watch, setValue } = useFormContext();
	const fieldKey = `billOfMaterials.${index}`;
	const amountFieldKey = `${fieldKey}.amount`;
	const titleFieldKey = `${fieldKey}.title.${lang}`;
	const amount = watch(amountFieldKey);

	return (
		<div style={{ display: 'flex' }}>
			<SimpleSelect
				options={_.range(0, 11).map(p => ({
					value: String(p),
					label: p ? String(p) : '-'
				}))}
				selected={amount}
				setSelected={v => setValue(amountFieldKey, parseInt(v))}
				minWidth={150}
				label="general.amount"
			/>
			<ControlledTextField name={titleFieldKey} label={t('general.title')} sx={{ marginLeft: 1 }} />
		</div>
	);
}
