import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { Insight, InsightHoldReason, Status } from '../../../../models/Insights';
import { UserModel } from '../../../../models/User';
import { useBuildInsightRequest, useInsightActions } from '../../../../stores/insights/InsightStore';
import { FormId } from '../../../form/FormId';
import { FreesiModal } from '../../FreesiModal';
import { HoldInsightFormSchema } from './getHoldInsightFormSchema';
import { HoldInsightForm } from './HoldInsightForm';

interface Props {
	insight: Insight;
	modalOpen: boolean;
	fixedReason?: InsightHoldReason;
	onClose: () => void;
	currentUser: UserModel;
}

export function HoldInsightModal({ modalOpen, onClose, fixedReason, currentUser, insight }: Props) {
	const { t } = useTypedTranslation();
	const { pauseInsight, sendCAPEX } = useInsightActions();
	const { buildInsightLogEntry } = useBuildInsightRequest();

	async function handleHoldInsight(data: HoldInsightFormSchema) {
		const logEntry = {
			responsible: data.responsibleEmail,
			expectedDate: data.expectedDate,
			message: data.message,
			attachments: data.attachments,
			reason: data.reason
		};
		const handleRequest = buildInsightLogEntry(logEntry, Status.PENDING);
		await pauseInsight(insight.id, handleRequest);

		if (data.capex) {
			const capexRequest = {
				...data.capex,
				requestedBy: currentUser.fullName,
				requestedDate: new Date().toISOString()
			};
			await sendCAPEX(insight.id, capexRequest);
		}
	}

	return (
		<FreesiModal
			maxWidth="sm"
			formId={FormId.INSIGHT}
			open={modalOpen}
			onCancel={onClose}
			cancelable
			submittable
			title={t('eventModal.hold.hold-insight')}>
			<HoldInsightForm onSubmit={handleHoldInsight} fixedReason={fixedReason} />
		</FreesiModal>
	);
}
