import { BillOfMaterialsEntry } from '../../model/InstallationJob';
import { SupportedLocale } from '../../../../models/LocalizedString';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import { FreesiButton } from '../../../styled/FreesiButton';
import { BomEntryEditor } from './BomEntryEditor';

interface Props extends BillOfMaterialsEntry {
	onRemove: () => void;

	lang: SupportedLocale;
	index: number;
}

export function BomEntry({ onRemove, lang, amount, title, index }: Props) {
	const [editing, setEditing] = useState(!title[lang]);

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			{editing ? (
				<BomEntryEditor index={index} lang={lang} />
			) : (
				<SemiBoldText>
					{!!amount && `${amount} x `}
					{title[lang]}
				</SemiBoldText>
			)}
			<div>
				<FreesiButton
					label={editing ? 'general.save' : 'general.edit'}
					icon={editing ? <SaveIcon /> : <EditIcon />}
					onClick={() => setEditing(s => !s)}
				/>
				<FreesiButton label="general.remove" icon={<DeleteIcon />} onClick={onRemove} />
			</div>
		</div>
	);
}

const SemiBoldText = styled.div`
	color: #00195f;
	font-weight: 500;
`;
