import { z } from 'zod';
import { TranslateFn } from '../hooks/useTypedTranslation';
import { zNullableOptional } from '../utils/zod/zNullableOptional';
import { zNumber } from '../utils/zod/zNumber';
import { measurementTypeSchema } from './Measurement';
import { Page } from './Page';
import { UserHeader } from './User';
import { OrganizationFeature } from './Organization';

export interface InsightSummaryModel {
	id: string;
	description: string;
	created: string | null;
	locationName: string | null;
	locationId: string | null;
	premiseName: string | null;
	premiseId: string | null;
	priority: number;
	status: string;
	responsible?: string;
	expectedDate?: Date;
}

export type InsightSummaryResponse = Page<InsightSummaryModel>;

export type InsightStateButtonStatus = Exclude<Status, Status.OPEN | Status.NO_RESPONSE | Status.PENDING>;

export interface Insight {
	id: string;
	created: Date;
	createdBy: string;
	updated: Date;
	premiseId?: string;
	premiseName?: string;
	locationId: string;
	locationName: string;
	description: string;
	notes: string;
	holdDuration: Date;
	insightMoment: Date;
	status: Status;
	conclusion: Conclusion;
	priority?: number;
	logEntries: InsightLogEntry[];
	processingComplete: boolean;
	latest?: Latest;
	tasks?: Task[];
	capexRequest?: CapexRequest;
	evidence?: Evidence;
	supervisor?: UserHeader;
}

export interface InsightLogEntry {
	created: string;
	status: Status;
	message: string;
	/**
	 * Log entry can be related to a specific task
	 */
	createdBy?: string;
	taskId?: string;
	/**
	 * Reason for latest action. e.g: hold insight
	 */
	reason?: string;
	/**
	 * Next responsible user's descriptor
	 */
	responsible?: string;
	expectedDate?: Date;
	attachments?: string[];
	type?: LogEntryType;
}

export enum Status {
	OPEN = 'OPEN',
	IN_PROGRESS = 'IN_PROGRESS',
	PENDING = 'PENDING',
	REPAIRED = 'REPAIRED',
	DECLINED = 'DECLINED',
	NO_RESPONSE = 'NO_RESPONSE'
}

export type Conclusion = {
	repairs: string[];
	causes: string[];
	isRational: boolean;
	timestamp: Date;
};

export interface InsightHandleRequest {
	insightLogEntry: Partial<InsightLogEntry>;
	status: Status;
	capexRequest?: CapexRequest;
	newTasks?: Task[];
}

export interface ManualInsightRequest {
	madeBy?: string;
	description: string;
	locationId: string;
	premiseId?: string;
	insightMoment: Date;
	priority: number;
	responsibleEmail: string;
	expectedDate: Date;
	tasks?: Task[];
	evidence?: Evidence;
	holdInsight: boolean;
}

export interface Latest {
	responsibleEmail: string;
	expectedDate: Date;
	status: Status;
	/**
	 * Reason for latest action. e.g: hold insight
	 */
	reason?: string;
}

export const taskSchema = z.object({
	id: z.string().optional(),
	description: z.string(),
	creatorId: zNullableOptional(z.string()),
	/**
	 * timestamp when the task is completed
	 * can set to undefined when redo complete task
	 */
	completed: zNullableOptional(z.string()),
	/**
	 * timestamp when the task is completed
	 * can set to undefined when redo complete task
	 */
	completedBy: zNullableOptional(z.string())
});

export type Task = z.infer<typeof taskSchema>;

export function getCapexRequestSchema(t: TranslateFn) {
	return z.object({
		reason: zNullableOptional(z.string()),
		amount: zNumber({ t }).optional(),
		requestedBy: zNullableOptional(z.string()),
		requestedDate: zNullableOptional(z.string()),
		decision: zNullableOptional(z.string()),
		decisionMaker: zNullableOptional(z.string()),
		decisionDate: zNullableOptional(z.string()),
		externalId: zNullableOptional(z.string())
	});
}

export type CapexRequest = z.infer<ReturnType<typeof getCapexRequestSchema>>;

export const evidenceSchema = z.object({
	measurementType: measurementTypeSchema.optional(),
	startTime: z.date().optional(),
	endTime: z.date().optional()
});

export type Evidence = z.infer<typeof evidenceSchema>;

export enum LogEntryType {
	COMMENT = 'COMMENT',
	TASK_COMPLETED = 'TASK_COMPLETED',
	GENERAL = 'GENERAL',
	RESPONSIBLE_REASSIGNMENT = 'RESPONSIBLE_REASSIGNMENT',
	SUPERVISOR_REASSIGNMENT = 'SUPERVISOR_REASSIGNMENT',
	DUEDATE_REASSIGNMENT = 'DUEDATE_REASSIGNMENT',
	PENDING_APPROVAL = 'PENDING_APPROVAL',
	PENDING_SUPPORT = 'PENDING_SUPPORT',
	EVIDENCE_UPDATED = 'EVIDENCE_UPDATED',
	APPROVAL_REJECTED = 'APPROVAL_REJECTED',
	CAPEX_DECISION = 'CAPEX_DECISION',
	PENDING_RESOLVED = 'PENDING_RESOLVED'
}

export enum InsightHoldReason {
	CAPEX = 'CAPEX',
	FOLLOWUP = 'FOLLOWUP',
	SUPERVISOR_DECLINE_APPROVAL = 'SUPERVISOR_DECLINE_APPROVAL',
	SUPERVISOR_REPAIR_APPROVAL = 'SUPERVISOR_REPAIR_APPROVAL',
	SUPERVISOR_SUPPORT = 'SUPERVISOR_SUPPORT',
	OTHERS = 'OTHERS'
}

export interface Approval {
	approver: string;
	approvalDate: Date;
	approvalType: ApprovalType;
	approvalStatus: ApprovalStatus;
	comment: string;
}

export enum ApprovalType {
	FINAL_APPROVAL
}

export enum ApprovalStatus {
	APPROVED,
	REJECTED
}

export function createApprovedApproval(approver: string, approvalDate: Date, comment: string): Approval {
	return {
		approver,
		approvalDate,
		approvalType: ApprovalType.FINAL_APPROVAL,
		approvalStatus: ApprovalStatus.APPROVED,
		comment
	};
}

export function createRejectedApproval(approver: string, approvalDate: Date, comment: string): Approval {
	return {
		approver,
		approvalDate,
		approvalType: ApprovalType.FINAL_APPROVAL,
		approvalStatus: ApprovalStatus.REJECTED,
		comment
	};
}

export interface UpdateInsightDetailsDTO {
	responsibleEmail?: string;
	expectedDate?: Date;
	// supervisor id
	supervisor?: string;
}

export interface InsightResponsibleModel {
	fullName: string;
	email: string;
}
