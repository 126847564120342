import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTypedTranslation } from '../../../../hooks/useTypedTranslation';
import { InputLabelWithRequired } from '../../../form/InputLabelWithRequired';
import { ControlledTextField } from '../../../form/controls/ControlledTextField';
import { InstallationJob } from '../../model/InstallationJob';

interface Props {
	stepNumber: number;
}

export function StepMetadataField({ stepNumber }: Props) {
	const form = useFormContext<InstallationJob>();
	const type = form.watch(`steps.${stepNumber}.type`);
	const { unsafeTranslation } = useTypedTranslation();
	if (!type) return null;

	const fields = getMetadataFields(type);
	if (fields.length === 0) return null;

	return (
		<>
			{fields.map(field => (
				<Fragment key={field}>
					<InputLabelWithRequired
						label=""
						labelRaw={unsafeTranslation(`installation.jobs.metadata.${field.toUpperCase()}`)}
						required
					/>
					<ControlledTextField name={`steps.${stepNumber}.metadata.${field}`} fullWidth={true} key={field} />
				</Fragment>
			))}
		</>
	);
}

function getMetadataFields(type: InstallationJob['steps'][0]['type']): string[] {
	switch (type) {
		default:
			return [];
	}
}
